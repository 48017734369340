<template>
  <fu-table
    align="top"
    :columns="columns"
    :data="transformedBeacons"
    :page.sync="page"
    :page-size.sync="pageSize"
    :page-sizes="[100, 500, 1000]"
    :sort-field.sync="sortField"
    :sort-order.sync="sortOrder"
    :row-url="beaconUrl"
  >
    <template #column-name="{ row }">
      <div class="cell-aligner">{{ row.name }}</div>
    </template>
    <template #column-build="{ row }">
      <template v-if="!row.build">
        <div class="cell-aligner">Never built</div>
      </template>
      <template v-else>
        <div class="cell-aligner">
          {{ row.build }}
        </div>
        <div class="build-meta">
          <div :class="['status', row.isUpToDate && 'is-up-to-date']">
            <font-awesome-icon
              :icon="
                row.isUpToDate ? icons.faCheck : icons.faExclamationTriangle
              "
            />
            <span>{{
              $t(
                row.isUpToDate
                  ? "beaconList.upToDate"
                  : "beaconList.updateAvailable"
              )
            }}</span>
          </div>
          <div>/</div>
          <div class="built-at">
            <template v-if="row.buildTime">
              {{ $t("beaconList.buildAt") }} {{ row.buildTime | timestamp }}
            </template>
          </div>
        </div>
      </template>
    </template>
    <template #column-owner="{ row }">
      <div v-if="row.owner && row.owner.username" class="cell-aligner">
        {{ row.owner.username }}
      </div>
    </template>
    <template #column-actions="{ row }">
      <fu-button
        :icon="icons.faArrowDown"
        size="small"
        @click.stop="$emit('download', row.name)"
      >
        {{ $t("beaconList.download") }}
      </fu-button>
    </template>
  </fu-table>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faArrowDown,
  faCheck,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import FuButton from "@/components/Button";
import FuTable from "@/components/LocalTable";
import timestamp from "@/filters/timestamp";
import timeOffset from "@/helpers/timeOffset";
import platforms from "@/services/platforms";

export default {
  inject: ["$app"],

  components: {
    FontAwesomeIcon,
    FuButton,
    FuTable,
  },

  filters: {
    timestamp,
  },

  props: {
    beacons: {
      type: Array,
    },
  },

  data() {
    return {
      columns: [
        {
          field: "name",
          label: this.$t("beaconList.beacon"),
          sortable: true,
          width: "99%",
        },
        {
          field: "owner",
          label: this.$t("beaconList.owner"),
          sortable: true,
          sortCompare(a, b) {
            return (a.owner?.username || "")
              .toLowerCase()
              .localeCompare(b.owner?.username.toLowerCase());
          },
        },
        {
          field: "build",
          label: this.$t("beaconList.buildVersion"),
          sortable: true,
        },
        {
          align: "right",
          field: "actions",
          label: this.$t("beaconList.actions"),
          noLink: true,
        },
      ],
      page: 1,
      pageSize: 100,
      sortField: "name",
      sortOrder: "asc",
    };
  },

  created() {
    this.icons = { faArrowDown, faCheck, faExclamationTriangle };
  },

  computed: {
    transformedBeacons() {
      return this.beacons.map((beacon) => ({
        ...beacon,
        build: this.buildLabel(beacon),
      }));
    },
  },

  methods: {
    timeOffset,

    buildLabel(beacon) {
      if (beacon.buildVersion && beacon.buildPlatform) {
        const platform = platforms.forName(beacon.buildPlatform);
        return `${beacon.buildVersion} - ${platform.displayName}`;
      } else if (beacon.buildVersion) {
        return beacon.buildVersion;
      }
    },

    beaconUrl(beacon) {
      return `/${this.$app.groupId}/beacons/${beacon.name}/edit`;
    },
  },
};
</script>

<style lang="scss" scoped>
.cell-aligner {
  align-items: center;
  display: flex;
  height: 2.25rem;
}

.build-meta {
  color: $grey-dark;
  display: flex;
  font-size: 0.9rem;
  margin-top: -0.25rem;
}

.build-meta > * + * {
  margin-left: 0.4rem;
}

.status {
  align-items: center;
  color: $yellow-dark;
  display: inline-flex;
}

.status > * + * {
  margin-left: 0.4rem;
}

.status.is-up-to-date {
  color: $green-dark;
}

.built-at {
  color: $grey-dark;
}
</style>
