<template>
  <div :class="classes">
    <div v-if="label" class="label">
      {{ label }}
    </div>
    <div class="control">
      <div v-if="prefix" class="prefix">
        {{ prefix }}
      </div>
      <div class="value">
        {{ valueLabel }}
      </div>
      <select
        class="input"
        :disabled="disabled"
        :value="value"
        @focus="focused = true"
        @blur="focused = false"
        @change="onChange"
      >
        <option
          v-for="(option, index) in options"
          :key="index"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    options: {
      type: Array,
    },
    prefix: {
      type: String,
    },
    size: {
      type: String,
    },
    value: {
      type: [Number, String],
    },
  },

  data() {
    return {
      focused: false,
    };
  },

  computed: {
    classes() {
      return {
        select: true,
        "is-focused": this.focused,
        "is-disabled": this.disabled,
        [`is-size-${this.size}`]: this.size,
      };
    },

    valueLabel() {
      return (
        this.options.find((o) => o.value === this.value) || { label: "Unknown" }
      ).label;
    },
  },

  methods: {
    onChange(evt) {
      this.$emit("input", evt.target.value || null);
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  margin: 0 0 0.25rem;
}

.control {
  align-items: center;
  background: $white url("../assets/images/select.png") no-repeat;
  background-position: calc(100% - 1rem) 50%;
  background-size: 0.5rem;
  border: 1px solid $grey-light;
  border-radius: 3px;
  box-shadow: 0 2px 1px $grey-lighter;
  color: $black;
  display: flex;
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

.prefix {
  color: $grey-darker;
  font-size: 0.9rem;
  font-weight: 500;
  margin-right: 0.5rem;
  text-transform: uppercase;
}

.value {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 240px;
}

.input {
  appearance: none;
  cursor: pointer;
  font: inherit;
  height: 100%;
  left: 0;
  opacity: 0.001;
  position: absolute;
  top: 0;
  width: 100%;
}

.is-focused .control {
  border-color: $blue;
  box-shadow: 0 0 0 3px $blue-lighter;
  outline: 0;
}

.is-disabled .input {
  background: url("../assets/images/select.png") no-repeat, $grey-lightest;
  background-position: calc(100% - 1rem) 50%;
  background-size: 0.5rem;
  color: $grey-darker;
  cursor: not-allowed;
}

.is-size-small .input {
  background-position: calc(100% - 0.7rem) 50%;
  background-size: 0.4rem;
  padding: 0.4rem 1.6rem 0.4rem 0.8rem;
  font-size: 0.9rem;
}
</style>
