<template>
  <div class="settings">
    <div class="nav">
      <router-link class="brand" to="/">
        <img :src="require(`@/assets/images/${$t('_paths.logo')}`)" alt="" />
      </router-link>
      <router-link class="go-back" :to="prevRoute">
        <font-awesome-icon :icon="icons.faArrowLeft" />
        <span>{{ $t("settings.goBack") }}</span>
      </router-link>
    </div>
    <div class="inner">
      <h1 class="page-title">{{ $t("settings.title") }}</h1>
      <fu-card class="section">
        <h2 class="section-title">{{ $t("changePasswordForm.title") }}</h2>
        <fu-form @submit.prevent="changePassword">
          <fu-stack>
            <fu-stack-item>
              <fu-text-input
                :label="$t('changePasswordForm.password')"
                :errors="
                  errorsFor(
                    $v.changePasswordForm.password,
                    $t('changePasswordForm.password')
                  )
                "
                type="password"
                v-model="$v.changePasswordForm.password.$model"
                @blur="() => $v.changePasswordForm.password.$touch()"
                @input="() => $v.changePasswordForm.password.$reset()"
              >
                <template v-if="!$v.changePasswordForm.password.$error" #help>
                  {{ $t("changePasswordForm.passwordHelp") }}
                </template>
              </fu-text-input>
            </fu-stack-item>
            <fu-stack-item>
              <fu-text-input
                :label="$t('changePasswordForm.repeatPassword')"
                :errors="
                  errorsFor(
                    $v.changePasswordForm.confirmation,
                    $t('changePasswordForm.repeatPassword')
                  )
                "
                type="password"
                v-model="$v.changePasswordForm.confirmation.$model"
              />
            </fu-stack-item>
            <fu-stack-item>
              <fu-button
                :disabled="$v.changePasswordForm.$invalid"
                full-width
                :loading="loading"
                primary
                type="submit"
              >
                {{ $t("changePasswordForm.update") }}
              </fu-button>
            </fu-stack-item>
          </fu-stack>
        </fu-form>
      </fu-card>
      <fu-card class="section">
        <h2 class="section-title">{{ $t("deleteAccountForm.title") }}</h2>
        <i18n path="deleteAccountForm.warning" tag="p" />
        <fu-form @submit.prevent="deleteAccount">
          <fu-stack>
            <fu-stack-item>
              <fu-text-input
                type="password"
                :errors="
                  errorsFor(
                    $v.deleteAccountForm.password,
                    $t('deleteAccountForm.password')
                  )
                "
                v-model="$v.deleteAccountForm.password.$model"
              >
                <template v-if="!$v.deleteAccountForm.password.$error" #help>
                  {{ $t("deleteAccountForm.passwordHelp") }}
                </template>
              </fu-text-input>
            </fu-stack-item>
            <fu-stack-item>
              <fu-button
                destructive
                :disabled="$v.deleteAccountForm.$invalid"
                full-width
                type="submit"
                >{{ $t("deleteAccountForm.submit") }}</fu-button
              >
            </fu-stack-item>
          </fu-stack>
        </fu-form>
      </fu-card>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { validationMixin } from "vuelidate";
import { required, sameAs } from "vuelidate/lib/validators";
import FuButton from "@/components/Button";
import FuCard from "@/components/Card";
import FuForm from "@/components/Form";
import FuStack from "@/components/Stack";
import FuStackItem from "@/components/StackItem";
import FuTextInput from "@/components/TextInput";
import { AppError } from "@/helpers/errors";
import Unauthenticated from "@/layouts/Unauthenticated";
import errorsMixin from "@/mixins/errors";
import auth from "@/services/auth";
import me from "@/services/me";
import password from "@/validators/password";

export default {
  inject: ["$auth", "$toast"],

  route: {
    meta: {
      layout: Unauthenticated,
    },
  },

  components: {
    FontAwesomeIcon,
    FuButton,
    FuCard,
    FuForm,
    FuStack,
    FuStackItem,
    FuTextInput,
  },

  mixins: [errorsMixin, validationMixin],

  async preload({ redirect, $auth }) {
    if ($auth.provider !== "internal") {
      return redirect("/");
    }
  },

  data() {
    return {
      prevRoute: null,
      loading: false,
      changePasswordForm: {
        password: null,
        confirmation: null,
      },
      deleteAccountForm: {
        password: null,
      },
    };
  },

  validations: {
    changePasswordForm: {
      password: {
        required,
        password,
      },
      confirmation: {
        required,
        sameAs: sameAs("password"),
      },
    },
    deleteAccountForm: {
      password: {
        required,
      },
    },
  },

  beforeRouteEnter(_to, from, next) {
    next((vm) => {
      vm.prevRoute = from.path;
    });
  },

  created() {
    this.icons = { faArrowLeft };
  },

  computed: {
    username() {
      return auth.user.username;
    },
  },

  methods: {
    async changePassword() {
      if (this.$v.changePasswordForm.$invalid) {
        return;
      }
      this.loading = true;
      try {
        await me.updatePassword(this.changePasswordForm.password);
        this.$toast.raise(this.$t("changePasswordForm.success"));
      } catch (error) {
        this.$toast.raiseError(
          AppError.create(this.$t("changePasswordForm.error"), error)
        );
      } finally {
        this.loading = false;
      }
    },
    async deleteAccount() {
      if (this.$v.deleteAccountForm.$invalid) {
        return;
      }
      try {
        await me.deleteAccount(this.deleteAccountForm.password);
        this.$toast.raise(this.$t("deleteAccountForm.success"));

        return this.$router.replace("/login");
      } catch (error) {
        this.$toast.raiseError(
          AppError.create(this.$t("deleteAccountForm.error"), error)
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  background-color: $grey-lightest;
  min-height: 100vh;
}

.nav {
  background-color: $blue-darkest;
  display: flex;
  height: 4.5rem;
}

.nav > .brand {
  align-items: center;
  display: flex;
  height: 100%;
  padding: 0 1.5rem;
}

.nav > .brand > img {
  display: block;
  max-height: 48px;
  max-width: 200px;
}

.nav > .go-back {
  align-items: center;
  color: $white;
  display: inline-flex;
  gap: 0.5rem;
  margin-left: auto;
  padding: 1rem 1.5rem;
  text-decoration: none;
}

.inner {
  margin: 0 auto;
  max-width: 480px;
  padding: 3rem 1rem;
  width: 100%;
}

.page-title {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0 0 1.5rem;
}

.section {
  padding: 1.5rem;
}

.section + .section {
  margin-top: 1.5rem;
}

.section-title {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0 0 1rem;
}

.section-title + p {
  margin: 1rem 0;
}
</style>
