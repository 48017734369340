export default function timeOffset(date = new Date()) {
  const offset = date.getTimezoneOffset();
  return `UTC${sign(offset)}${hours(offset)}:${minutes(offset)}`;
}

function sign(offset) {
  return offset <= 0 ? "+" : "-";
}

function hours(offset) {
  const hours = Math.floor(Math.abs(offset) / 60);
  return hours < 10 ? "0" + hours : String(hours);
}

function minutes(offset) {
  const minutes = Math.abs(offset) % 60;
  return minutes < 10 ? "0" + minutes : String(minutes);
}
