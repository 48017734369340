import Cookies from "js-cookie";
import api from "@/services/api";

export default {
  logIn(username, password) {
    return api.post("/v3/login", { username, password });
  },

  async logOut() {
    await api.delete("/v3/logout");
    Cookies.remove("user");
  },

  async session() {
    return api.get("/v3/session");
  },

  get user() {
    try {
      return JSON.parse(Cookies.get("user"));
    } catch {
      return null;
    }
  },
};
