<template>
  <button class="menu-item" @click="handleClick">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
    },
  },

  computed: {
    isExternalLink() {
      const { route } = this.$router.resolve(this.to);
      return route.name === "404";
    },
  },

  methods: {
    handleClick(event) {
      if (this.to && this.isExternalLink) {
        window.open(this.to, "_blank");
      } else if (this.to) {
        this.$router.push(this.to);
      } else {
        this.$emit("click", event);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-item {
  appearance: none;
  background: none;
  border: 0;
  color: inherit;
  cursor: pointer;
  display: block;
  font: inherit;
  padding: 0.5rem 1rem;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;
}

.menu-item:hover {
  background: $grey-lighter;
}
</style>
