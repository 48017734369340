<template>
  <div class="dropdown-picker">
    <label v-if="label" class="label">
      {{ label }}
    </label>
    <fu-text-input
      v-model="query"
      :placeholder="placeholder"
      @focus="showResults = true"
      @blur="showResults = false"
      @keydown.up="onArrowUp"
      @keydown.down="onArrowDown"
      @keydown.enter.prevent="onEnter"
    />
    <div
      v-if="showResults && filteredOptions.length"
      class="results"
      @mousedown.prevent
    >
      <button
        v-for="(option, index) in filteredOptions"
        :key="option.value"
        :class="['item', { 'is-active': activeIndex === index }]"
        type="button"
        @click.prevent="select(option.value)"
        @focus="activeIndex = index"
        @mouseenter="activeIndex = index"
      >
        {{ option.label }}
      </button>
    </div>
    <transition-group
      v-if="value.length"
      appear
      tag="div"
      name="selected"
      class="selected"
    >
      <div class="item" v-for="item in value" :key="item">
        <div class="name">
          {{ item }}
        </div>
        <button class="remove" type="button" @click="remove(item)">
          {{ $t("dropdownPicker.remove") }}
        </button>
      </div>
    </transition-group>
    <div v-if="$slots.help" class="help">
      <div class="icon">
        <font-awesome-icon :icon="icons.faQuestionCircle" />
      </div>
      <div class="body">
        <slot name="help" />
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import FuTextInput from "@/components/TextInput";

export default {
  components: {
    FontAwesomeIcon,
    FuTextInput,
  },

  props: {
    label: {
      type: String,
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    placeholder: {
      type: String,
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      activeIndex: 0,
      query: "",
      showResults: false,
    };
  },

  created() {
    this.icons = { faQuestionCircle };
  },

  watch: {
    query() {
      this.activeIndex = 0;
    },
  },

  computed: {
    filteredOptions() {
      return this.options.filter(
        (option) =>
          !this.value.includes(option.label) &&
          option.label.toLowerCase().includes(this.query.toLowerCase())
      );
    },
  },

  methods: {
    onArrowUp() {
      this.activeIndex = Math.max(0, this.activeIndex - 1);
    },

    onArrowDown() {
      this.activeIndex = Math.min(
        this.filteredOptions.length - 1,
        this.activeIndex + 1
      );
    },

    onEnter(evt) {
      if (this.filteredOptions.length) {
        this.select(this.filteredOptions[this.activeIndex].value);
        this.activeIndex = Math.max(0, this.activeIndex - 1);
      }
    },

    remove(item) {
      const newValue = [...this.value];
      newValue.splice(this.value.indexOf(item), 1);
      this.$emit("input", newValue);
    },

    select(item) {
      const newValue = [...this.value];
      newValue.push(item);
      newValue.sort();
      this.$emit("input", newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  display: block;
  margin-bottom: 0.25rem;
}

.results {
  border: 1px solid $grey;
  border-radius: 3px;
  box-shadow: 0 6px 9px rgba($black, 0.2);
  max-height: 240px;
  overflow-y: auto;
}

.results > .item {
  appearance: none;
  background: $white;
  border: 0;
  cursor: pointer;
  display: block;
  font: inherit;
  outline: none;
  padding: 0.5rem 1rem;
  text-align: left;
  width: 100%;
}

.results > .item.is-active {
  background: $blue;
  color: $white;
}

.selected-enter-active {
  transition: all 0.3s;
}

.selected-enter {
  opacity: 0;
}

.selected {
  background: $grey-lightest;
  border: 1px solid $grey-light;
  border-radius: 3px;
  margin-top: 0.5rem;
}

.selected > .item {
  display: flex;
}

.selected > .item:not(:last-child) {
  border-bottom: 1px solid $grey-light;
}

.selected > .item > .name {
  flex: 1;
  padding: 0.5rem 1rem;
}

.selected > .item > .remove {
  background: none;
  border: 0;
  color: $grey-darker;
  cursor: pointer;
  padding: 0.5rem 1rem;
  text-decoration: underline;
}

.help {
  color: $grey-dark;
  display: flex;
  font-size: 0.9rem;
  margin-top: 0.25rem;
}

.help > .icon {
  margin-right: 0.25rem;
}
</style>
