<template>
  <div class="filterable-select" v-on-outside-click="hideOptions">
    <button class="control" type="button" @click="toggleOptions">
      <div v-if="prefix" class="prefix">
        {{ prefix }}
      </div>
      <div class="value">
        <slot name="value" v-bind="{ value }" />
      </div>
    </button>
    <div v-if="optionsVisible && options.length" class="options">
      <div class="query-field">
        <fu-text-input
          ref="query"
          v-model="query"
          :placeholder="placeholder"
          @keydown.up="onArrowUp"
          @keydown.down="onArrowDown"
          @keydown.enter.prevent="onEnter"
          @keydown.esc.prevent="hideOptions"
        />
      </div>
      <div ref="results" class="results">
        <div
          v-if="!filteredOptions.length && !!$scopedSlots['empty-results']"
          class="empty-results"
        >
          <slot name="empty-results" v-bind="{ query }" />
        </div>
        <button
          v-for="(option, index) in filteredOptions"
          :key="option.key || option.value"
          :class="['item', { 'is-active': activeIndex === index }]"
          type="button"
          @click.prevent="select(option.value)"
          @focus="activeIndex = index"
          @mouseenter="activeIndex = index"
        >
          <slot name="item" v-bind="{ option }" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FuTextInput from "@/components/TextInput";
import onOutsideClick from "@/directives/onOutsideClick";

export default {
  components: {
    FuTextInput,
  },

  directives: {
    onOutsideClick,
  },

  props: {
    filter: {
      type: Function,
      default() {
        return () => true;
      },
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    placeholder: {
      type: String,
    },
    prefix: {
      type: String,
    },
    value: {
      type: [String, Object],
    },
  },

  data() {
    return {
      activeIndex: 0,
      query: "",
      optionsVisible: false,
    };
  },

  watch: {
    query() {
      this.activeIndex = 0;
      this.$refs.results.scrollTop = 0;
    },
  },

  computed: {
    filteredOptions() {
      const query = this.query.toLowerCase();
      return this.options.filter((option) => this.filter(query, option));
    },
  },

  methods: {
    hideOptions() {
      this.optionsVisible = false;
    },

    onArrowUp() {
      this.activeIndex = Math.max(0, this.activeIndex - 1);
      this.scrollToActiveItem();
    },

    onArrowDown() {
      this.activeIndex = Math.min(
        this.filteredOptions.length - 1,
        this.activeIndex + 1
      );
      this.scrollToActiveItem();
    },

    onEnter() {
      if (this.filteredOptions.length) {
        this.select(this.filteredOptions[this.activeIndex].value);
      }
    },

    scrollToActiveItem() {
      const element = this.$refs.results.children[this.activeIndex];
      if (element) {
        this.$refs.results.scrollTop = element.offsetTop;
      }
    },

    select(option) {
      this.$emit("input", option);
      this.hideOptions();
    },

    showOptions() {
      this.optionsVisible = true;
      this.$nextTick(() => {
        this.$refs.query.select();
        this.scrollToActiveItem();
      });
    },

    toggleOptions() {
      if (this.optionsVisible) {
        this.hideOptions();
      } else {
        this.showOptions();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.filterable-select {
  position: relative;
}

.control {
  align-items: center;
  appearance: none;
  background: $white url("../assets/images/select.png") no-repeat;
  background-position: calc(100% - 1rem) 50%;
  background-size: 0.5rem;
  border: 1px solid $grey-light;
  border-radius: 3px;
  box-shadow: 0 2px 1px $grey-lighter;
  color: $black;
  cursor: pointer;
  display: flex;
  font: inherit;
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

.prefix {
  color: $grey-darker;
  font-size: 0.9rem;
  font-weight: 500;
  margin-right: 0.5rem;
  text-transform: uppercase;
}

.value {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 240px;
}

.query-field {
  background: $grey-lightest;
  border-bottom: 1px solid $grey-light;
  padding: 0.5rem;
}

.options {
  background: $white;
  border: 1px solid $grey;
  border-radius: 3px;
  box-shadow: 0 6px 9px rgba($black, 0.2);
  position: absolute;
  right: 0;
  z-index: 1;
  width: 400px;
}

.results {
  max-height: 240px;
  overflow-y: auto;
  position: relative;
}

.results > .item {
  appearance: none;
  background: $white;
  border-color: rgba($black, 0.05);
  border-width: 0 0 1px;
  cursor: pointer;
  display: block;
  font: inherit;
  outline: none;
  padding: 0.5rem 1rem;
  text-align: left;
  width: 100%;
}

.results > .item.is-active {
  background: $blue-lightest;
}

.empty-results {
  background: #fffff0;
  border: 1px solid #ecc94b;
  border-radius: 3px;
  color: #975a16;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
}

@media screen and (max-width: 1023px) {
  .options {
    left: 0;
    right: auto;
  }
}
</style>
