<template>
  <div class="stats">
    <div class="icon">
      <slot name="icon" />
    </div>
    <div class="content">
      <div class="title" v-tooltip="{ content: help, classes: ['tooltip'] }">
        {{ label }}
        <font-awesome-icon class="help-icon" :icon="icons.faQuestionCircle" />
      </div>
      <div class="body"><slot /></div>
    </div>
  </div>
</template>

<script>
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  components: {
    FontAwesomeIcon,
  },

  props: {
    help: {
      type: String,
    },
    label: {
      type: String,
    },
  },

  created() {
    this.icons = { faQuestionCircle };
  },
};
</script>

<style lang="scss" scoped>
.stats {
  align-items: center;
  display: flex;
}

.icon {
  align-items: center;
  background: $blue-dark;
  border-radius: 2rem;
  color: $white;
  display: inline-flex;
  flex-shrink: 0;
  font-size: 1.2rem;
  height: 3.2rem;
  justify-content: center;
  width: 3.2rem;
}

.content {
  flex: 1;
  margin-left: 1.5rem;
}

.title {
  align-items: center;
  display: inline-flex;
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: uppercase;
}

.help-icon {
  color: $grey-dark;
  display: inline-flex;
  margin-left: 0.5rem;
}

.body {
  font-size: 1.4rem;
}
</style>
