<template>
  <fu-dialog
    :title="$t('deleteDialog.title', [target])"
    width="narrow"
    @close="$emit('close')"
  >
    <template v-if="!!$scopedSlots.default">
      <slot v-bind="{ target }" />
    </template>
    <i18n v-else path="deleteDialog.confirmation" tag="p">
      <strong>{{ target }}</strong>
    </i18n>
    <template slot="actions">
      <fu-button-group>
        <fu-button @click="$emit('close')">
          {{ $t("deleteDialog.cancel") }}
        </fu-button>
        <fu-button primary :loading="loading" @click="confirm">
          {{ $t("deleteDialog.delete") }}
        </fu-button>
      </fu-button-group>
    </template>
  </fu-dialog>
</template>

<script>
import FuButton from "@/components/Button";
import FuButtonGroup from "@/components/ButtonGroup";
import FuDialog from "@/components/Dialog";
import { AppError } from "@/helpers/errors";

export default {
  inject: ["$toast"],

  components: {
    FuButton,
    FuButtonGroup,
    FuDialog,
  },

  props: {
    delete: {
      type: Function,
      required: true,
    },
    target: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    async confirm() {
      this.loading = true;
      try {
        await this.delete();
        this.$toast.raise(this.$t("deleteDialog.success", [this.target]));
        this.$emit("success");
      } catch (error) {
        this.$toast.raiseError(
          AppError.create(this.$t("deleteDialog.error", [this.target]), error)
        );
        this.$emit("error", error);
      } finally {
        this.loading = false;
        this.$emit("close");
      }
    },
  },
};
</script>
