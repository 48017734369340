<template>
  <fu-form ref="form" @submit.prevent="submit">
    <fu-annotated-section :title="$t('beaconForm.general')">
      <fu-notification v-if="!editable" :title="$t('beaconForm.readOnlyTitle')">
        {{ $t("beaconForm.readOnly") }}
      </fu-notification>
      <fu-card>
        <fu-card-section>
          <fu-stack>
            <fu-stack-item>
              <fu-text-input
                :label="$t('beaconForm.name')"
                :disabled="!editable"
                :errors="errorsFor($v.config.name, $t('beaconForm.name'))"
                v-model="$v.config.name.$model"
              >
                <template slot="help">
                  {{ $t("beaconForm.nameHelp") }}
                </template>
              </fu-text-input>
            </fu-stack-item>
            <fu-stack-item>
              <fu-select
                :label="$t('beaconForm.platform')"
                :disabled="!editable"
                :options="platforms"
                v-model="$v.config.platform.$model"
              />
            </fu-stack-item>
          </fu-stack>
        </fu-card-section>
      </fu-card>
    </fu-annotated-section>
    <fu-annotated-section :title="$t('beaconForm.metaInformation')">
      <fu-stack>
        <fu-stack-item
          v-for="v in $v.config.meta.$each.$iter"
          :key="v.key.$model"
        >
          <fu-card>
            <fu-card-section>
              <fu-stack>
                <fu-stack-item>
                  <fu-text-input
                    disabled
                    :errors="errorsFor(v.key, $t('beaconForm.metaKey'))"
                    :label="$t('beaconForm.metaKey')"
                    v-model="v.key.$model"
                    @input="v.$touch"
                  />
                </fu-stack-item>
                <fu-stack-item
                  v-for="(vv, index) in v.value.$each.$iter"
                  :key="index"
                >
                  <div v-if="index == 0" class="meta-value-label">
                    {{ $t("beaconForm.metaValue") }}
                  </div>
                  <fu-stack row gap="small">
                    <fu-stack-item span="fill">
                      <fu-text-input
                        :ref="metaValueRefKey(v.key.$model, index)"
                        :errors="errorsFor(vv, $t('beaconForm.metaValue'))"
                        v-model="v.value.$model[index]"
                        @input="vv.$touch"
                        @keypress.enter.prevent="addMetaValue(v.key.$model)"
                      />
                    </fu-stack-item>
                    <fu-stack-item>
                      <fu-button
                        :icon="icons.faMinus"
                        @click="removeMetaValue(v.key.$model, index)"
                      />
                    </fu-stack-item>
                    <fu-stack-item>
                      <fu-button
                        :icon="icons.faAdd"
                        @click="addMetaValue(v.key.$model)"
                      />
                    </fu-stack-item>
                  </fu-stack>
                </fu-stack-item>
              </fu-stack>
            </fu-card-section>
          </fu-card>
        </fu-stack-item>
        <fu-stack-item>
          <fu-card>
            <fu-card-section>
              <fu-stack>
                <fu-stack-item>
                  <fu-text-input
                    ref="newMetaKey"
                    :label="$t('beaconForm.metaKey')"
                    :errors="
                      !$v.newMeta.key.isUnique
                        ? [$t('validations.unique', [$t('beaconForm.metaKey')])]
                        : errorsFor($v.newMeta.key, $t('beaconForm.metaKey'))
                    "
                    v-model="$v.newMeta.key.$model"
                    @input="$v.newMeta.key.$touch"
                    @keypress.enter.prevent="addMetaItem"
                  />
                </fu-stack-item>
                <fu-stack-item>
                  <fu-text-input
                    :label="$t('beaconForm.metaValue')"
                    :errors="
                      errorsFor($v.newMeta.value, $t('beaconForm.metaValue'))
                    "
                    v-model="$v.newMeta.value.$model"
                    @input="$v.newMeta.value.$touch"
                    @keypress.enter.prevent="addMetaItem"
                  />
                </fu-stack-item>
                <fu-stack-item>
                  <fu-button
                    :disabled="$v.newMeta.$invalid"
                    type="button"
                    @click="addMetaItem"
                    >{{ $t("beaconForm.addMetaItem") }}</fu-button
                  >
                </fu-stack-item>
              </fu-stack>
            </fu-card-section>
          </fu-card>
        </fu-stack-item>
      </fu-stack>
    </fu-annotated-section>
    <fu-annotated-section :title="$t('beaconForm.escape')">
      <fu-card>
        <fu-card-section>
          <fu-text-input
            :label="$t('beaconForm.dnsDomain')"
            :disabled="!editable"
            :errors="errorsFor($v.config.dnsDomain, $t('beaconForm.dnsDomain'))"
            v-model="$v.config.dnsDomain.$model"
          />
        </fu-card-section>
        <fu-card-section :title="$t('beaconForm.homeAddresses')">
          <fu-stack>
            <fu-stack-item
              v-for="(v, index) in $v.config.receivers.$each.$iter"
              :key="index"
            >
              <fu-stack row>
                <fu-stack-item span="fill">
                  <fu-text-input
                    :disabled="!editable"
                    :errors="errorsFor(v, $t('beaconForm.homeAddress'))"
                    v-model="$v.config.$model.receivers[index]"
                    @input="v.$touch"
                  />
                </fu-stack-item>
                <fu-stack-item>
                  <fu-button
                    :disabled="
                      !editable || $v.config.$model.receivers.length === 1
                    "
                    :icon="icons.faTrashAlt"
                    @click="removeReceiver(index)"
                  />
                </fu-stack-item>
              </fu-stack>
            </fu-stack-item>
            <fu-stack-item>
              <fu-button :disabled="!editable" @click="addReceiver">
                {{ $t("beaconForm.addHomeAddress") }}
              </fu-button>
            </fu-stack-item>
          </fu-stack>
        </fu-card-section>
        <fu-card-section :title="$t('beaconForm.dnsServers')">
          <fu-stack>
            <fu-stack-item
              v-for="(v, index) in $v.config.dns.$each.$iter"
              :key="index"
            >
              <fu-stack row>
                <fu-stack-item span="fill">
                  <fu-text-input
                    :disabled="!editable"
                    :errors="errorsFor(v, $t('beaconForm.dnsServer'))"
                    v-model="$v.config.$model.dns[index]"
                    @input="v.$touch"
                  />
                </fu-stack-item>
                <fu-stack-item>
                  <fu-button
                    :disabled="!editable"
                    :icon="icons.faTrashAlt"
                    @click="removeDnsServer(index)"
                  />
                </fu-stack-item>
              </fu-stack>
            </fu-stack-item>
            <fu-stack-item>
              <fu-button :disabled="!editable" @click="addDns">
                {{ $t("beaconForm.addDnsServer") }}
              </fu-button>
            </fu-stack-item>
          </fu-stack>
        </fu-card-section>
      </fu-card>
    </fu-annotated-section>
    <fu-annotated-section
      v-if="!hideInterface"
      :title="$t('beaconForm.interface')"
    >
      <fu-card>
        <fu-card-section>
          <fu-stack>
            <fu-stack-item>
              <fu-switch
                :label="$t('beaconForm.ipv4Dhcp')"
                :disabled="!editable"
                :value="$v.config.ipv4.$model.dhcp"
                @input="toggleIpv4Dhcp"
              />
            </fu-stack-item>
            <fu-stack-item v-if="!config.ipv4.dhcp">
              <fu-stack>
                <fu-stack-item>
                  <fu-text-input
                    :label="$t('beaconForm.ipAddress')"
                    :disabled="!editable"
                    :errors="
                      errorsFor(
                        $v.config.ipv4.static.ip,
                        $t('beaconForm.ipAddress')
                      )
                    "
                    v-model="$v.config.ipv4.static.ip.$model"
                  />
                </fu-stack-item>
                <fu-stack-item>
                  <fu-text-input
                    :label="$t('beaconForm.netmask')"
                    :disabled="!editable"
                    :errors="
                      errorsFor(
                        $v.config.ipv4.static.netmask,
                        $t('beaconForm.netmask')
                      )
                    "
                    v-model="$v.config.ipv4.static.netmask.$model"
                  />
                </fu-stack-item>
                <fu-stack-item>
                  <fu-text-input
                    :label="$t('beaconForm.gatewayAddress')"
                    :disabled="!editable"
                    :errors="
                      errorsFor(
                        $v.config.ipv4.static.gateway,
                        $t('beaconForm.gatewayAddress')
                      )
                    "
                    v-model="$v.config.ipv4.static.gateway.$model"
                  />
                </fu-stack-item>
              </fu-stack>
            </fu-stack-item>
          </fu-stack>
        </fu-card-section>
        <fu-card-section>
          <fu-stack>
            <fu-stack-item>
              <fu-switch
                :label="$t('beaconForm.ipv6Autoconfig')"
                :disabled="!editable"
                :value="$v.config.ipv6.$model.auto"
                @input="toggleIpv6Auto"
              />
            </fu-stack-item>
            <fu-stack-item v-if="!config.ipv6.auto">
              <fu-stack>
                <fu-stack-item>
                  <fu-text-input
                    :label="$t('beaconForm.ipAddress')"
                    :disabled="!editable"
                    :errors="
                      errorsFor(
                        $v.config.ipv6.static.ip,
                        $t('beaconForm.ipAddress')
                      )
                    "
                    v-model="$v.config.ipv6.static.ip.$model"
                  />
                </fu-stack-item>
                <fu-stack-item>
                  <fu-text-input
                    :label="$t('beaconForm.netmask')"
                    :disabled="!editable"
                    :errors="
                      errorsFor(
                        $v.config.ipv6.static.netmask,
                        $t('beaconForm.netmask')
                      )
                    "
                    v-model="$v.config.ipv6.static.netmask.$model"
                  />
                </fu-stack-item>
                <fu-stack-item>
                  <fu-text-input
                    :label="$t('beaconForm.gatewayAddress')"
                    :disabled="!editable"
                    :errors="
                      errorsFor(
                        $v.config.ipv6.static.gateway,
                        $t('beaconForm.gatewayAddress')
                      )
                    "
                    v-model="$v.config.ipv6.static.gateway.$model"
                  />
                </fu-stack-item>
              </fu-stack>
            </fu-stack-item>
          </fu-stack>
        </fu-card-section>
      </fu-card>
    </fu-annotated-section>
    <fu-annotated-section>
      <fu-button-group>
        <fu-button :to="`/${$app.groupId}/beacons`">
          {{ $t("beaconForm.cancel") }}
        </fu-button>
        <fu-button primary type="submit" :saving="saving">
          {{ $t("beaconForm.save") }}
        </fu-button>
      </fu-button-group>
    </fu-annotated-section>
  </fu-form>
</template>

<script>
import { faAdd, faMinus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { validationMixin } from "vuelidate";
import { maxLength, required } from "vuelidate/lib/validators";
import FuAnnotatedSection from "@/components/AnnotatedSection";
import FuButton from "@/components/Button";
import FuButtonGroup from "@/components/ButtonGroup";
import FuCard from "@/components/Card";
import FuCardSection from "@/components/CardSection";
import FuForm from "@/components/Form";
import FuNotification from "@/components/Notification";
import FuSelect from "@/components/Select";
import FuStack from "@/components/Stack";
import FuStackItem from "@/components/StackItem";
import FuSwitch from "@/components/Switch";
import FuTextInput from "@/components/TextInput";
import { AppError } from "@/helpers/errors";
import errorsMixin from "@/mixins/errors";
import platforms from "@/services/platforms";
import beaconName from "@/validators/beaconName";
import domain from "@/validators/domain";
import metaKey from "@/validators/metaKey";
import metaValue from "@/validators/metaValue";
import ip, { ipv4, ipv6 } from "@/validators/ip";

export default {
  inject: ["$app", "$toast"],

  components: {
    FuAnnotatedSection,
    FuButton,
    FuButtonGroup,
    FuCard,
    FuCardSection,
    FuForm,
    FuNotification,
    FuSelect,
    FuStack,
    FuStackItem,
    FuSwitch,
    FuTextInput,
  },

  mixins: [errorsMixin, validationMixin],

  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    initialConfig: {
      type: Object,
      default() {
        return {};
      },
    },
    save: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      config: {
        dns: [],
        dnsDomain: null,
        ipv4: {
          dhcp: true,
          static: null,
        },
        ipv6: {
          auto: true,
          static: null,
        },
        meta: {},
        name: null,
        platform: "ova",
        receivers: [],
        ...this.initialConfig,
      },
      icons: {
        faAdd,
        faMinus,
        faTrashAlt,
      },
      newMeta: {
        key: null,
        value: null,
      },
      saving: false,
      platforms: platforms.options,
    };
  },

  validations() {
    const rules = {
      config: {
        dns: {
          $each: {
            ip,
            required,
          },
        },
        dnsDomain: {
          domain,
        },
        ipv4: {
          required,
        },
        ipv6: {
          required,
        },
        meta: {
          $each: {
            key: {
              metaKey,
              required,
            },
            value: {
              required,
              $each: {
                metaValue,
                required,
              },
            },
          },
        },
        name: {
          beaconName,
          maxLength: maxLength(32),
          required,
        },
        platform: {
          required,
        },
        receivers: {
          required,
          $each: {
            ip,
            required,
          },
        },
      },
      newMeta: {
        key: {
          metaKey,
          required,
          isUnique: this.isUniqueMetaKey,
        },
        value: {
          metaValue,
          required,
        },
      },
    };
    if (!this.config.ipv4.dhcp) {
      rules.config.ipv4.static = {
        required,
        gateway: {
          ipv4,
          required,
        },
        ip: {
          ipv4,
          required,
        },
        netmask: {
          ipv4,
          required,
        },
      };
    }
    if (!this.config.ipv6.auto) {
      rules.config.ipv6.static = {
        required,
        gateway: {
          ipv6,
          required,
        },
        ip: {
          ipv6,
          required,
        },
        netmask: {
          required,
        },
      };
    }
    return rules;
  },

  created() {
    const meta = Object.keys(this.config.meta).map((key) => ({
      key,
      value: this.config.meta[key],
    }));
    meta.sort((a, b) => a.key.localeCompare(b.key));
    this.config.meta = meta;
  },

  computed: {
    hideInterface() {
      const platform = platforms.forName(this.config.platform);
      return !!platform && platform.hideInterface;
    },
  },

  methods: {
    addDns() {
      if (this.config.dns == null) {
        this.config.dns = [];
      }
      this.config.dns.push("");
    },

    addMetaItem() {
      if (this.$v.newMeta.$invalid) {
        return;
      }
      this.config.meta = [
        ...this.config.meta,
        { key: this.newMeta.key, value: [this.newMeta.value] },
      ];
      this.newMeta = { key: null, value: null };
      this.$v.newMeta.$reset();
      this.$nextTick(() => this.$refs.newMetaKey.focus());
    },

    addMetaValue(key) {
      const item = this.config.meta.find((item) => item.key === key);
      if (item == null) {
        return;
      }
      item.value.push("");
      this.$nextTick(() => {
        this.$refs[this.metaValueRefKey(key)][item.value.length - 1].focus();
      });
    },

    addReceiver() {
      this.config.receivers.push("");
    },

    isUniqueMetaKey(key) {
      return !this.config.meta.find((item) => item.key === key);
    },

    metaValueRefKey(key) {
      return `metaValue${key}`;
    },

    removeDnsServer(index) {
      this.config.dns.splice(index, 1);
    },

    removeMetaItem(key) {
      this.config.meta = this.config.meta.filter((item) => item.key !== key);
    },

    removeMetaValue(key, index) {
      const item = this.config.meta.find((item) => item.key === key);
      if (item == null) {
        return;
      }
      item.value.splice(index, 1);
      if (!item.value.length) {
        this.removeMetaItem(key);
      }
    },

    removeReceiver(index) {
      this.config.receivers.splice(index, 1);
    },

    toggleIpv4Dhcp(dhcp) {
      if (!dhcp) {
        this.config.ipv4.static = {
          gateway: "",
          ip: "",
          netmask: "",
        };
      } else {
        this.config.ipv4.static = null;
      }
      this.config.ipv4.dhcp = dhcp;
    },

    toggleIpv6Auto(auto) {
      if (!auto) {
        this.config.ipv6.static = {
          gateway: "",
          ip: "",
          netmask: "",
        };
      } else {
        this.config.ipv6.static = null;
      }
      this.config.ipv6.auto = auto;
    },

    async submit() {
      this.$v.config.$touch();
      if (this.$v.config.$invalid) {
        this.$nextTick(() => this.$refs.form.scrollToFirstError());
        return;
      }
      this.saving = true;
      try {
        const meta = this.config.meta.reduce(
          (result, item) => ({
            ...result,
            [item.key]: item.value,
          }),
          {}
        );
        await this.save({ ...this.config, meta });
        this.$toast.raise(this.$t("beaconForm.saveSuccess"));
        this.$emit("after-save", this.config);
      } catch (error) {
        this.$toast.raiseError(
          AppError.create(this.$t("beaconForm.saveError"), error)
        );
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.meta-value-label {
  margin-bottom: 0.25rem;
}
</style>
