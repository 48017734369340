<template>
  <fu-container width="narrow">
    <fu-base-view :title="$t('configure.users')" :actions="actions">
      <fu-annotated-section :title="$t('users.allUsers')">
        <fu-card>
          <fu-user-list
            :users="users"
            @edit="(user) => (editedUser = user)"
            @delete="(user) => (removedUser = user)"
          />
        </fu-card>
      </fu-annotated-section>
      <fu-remove-user-dialog
        v-if="removedUser"
        :username="removedUser"
        @close="removedUser = null"
        @success="refresh"
      />
      <fu-dialog
        v-if="inviteToken"
        :title="$t('users.inviteDialogTitle')"
        @close="inviteToken = null"
      >
        <p>{{ $t("users.inviteDialogHelp") }}</p>
        <fu-text-input ref="inviteLink" readonly :value="inviteLink">
          <template slot="append">
            <fu-button primary :icon="icons.faCopy" @click="copyLink">
              {{ $t("users.copyInvite") }}
            </fu-button>
          </template>
        </fu-text-input>
      </fu-dialog>
    </fu-base-view>
  </fu-container>
</template>

<script>
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import FuAnnotatedSection from "@/components/AnnotatedSection";
import FuBaseView from "@/components/BaseView";
import FuButton from "@/components/Button";
import FuCard from "@/components/Card";
import FuContainer from "@/components/Container";
import FuDialog from "@/components/Dialog";
import FuRemoveUserDialog from "./_components/RemoveUserDialog";
import FuTextInput from "@/components/TextInput";
import FuUserList from "@/components/UserList";
import users from "@/services/users";

export default {
  inject: ["$app", "$auth", "$progress"],

  components: {
    FuAnnotatedSection,
    FuBaseView,
    FuButton,
    FuCard,
    FuContainer,
    FuDialog,
    FuRemoveUserDialog,
    FuTextInput,
    FuUserList,
  },

  async preload({ $app }) {
    const { users: list, pending } = await users.list($app.groupId);
    return {
      users: [...list].sort((a, b) => a.username.localeCompare(b.username)),
      pending,
    };
  },

  data() {
    return {
      editedUser: null,
      icons: { faCopy },
      inviteBusy: false,
      inviteToken: null,
      removedUser: null,
    };
  },

  computed: {
    actions() {
      return [
        {
          handler: this.createInvite,
          loading: this.inviteBusy,
          primary: true,
          text: this.$t("users.createInvite"),
        },
      ];
    },

    inviteLink() {
      const token = window.encodeURIComponent(this.inviteToken);
      return `${window.location.origin}/join?token=${token}`;
    },
  },

  methods: {
    copyLink() {
      this.$refs.inviteLink.copy();
    },

    async createInvite() {
      this.inviteBusy = true;
      try {
        this.inviteToken = await users.invite(this.$app.groupId);
        this.$emit("success");
      } catch (error) {
        this.$toast.raise(this.$t("users.inviteError"));
      } finally {
        this.inviteBusy = false;
      }
    },

    async refresh() {
      this.$progress.start();
      const { users: list, pending } = await users.list(this.$app.groupId);
      this.users = list;
      this.pending = pending;
      this.$progress.finish();
    },
  },
};
</script>
