import api from "@/services/api";

export default {
  async create(groupId, { description, acl }) {
    const {
      data: { token },
    } = await api.post(`/v3/${groupId}/tokens`, { description, acl });
    return token;
  },

  async delete(groupId, id) {
    return api.delete(`/v3/${groupId}/tokens/${id}`);
  },

  isBeaconFilterable(aclPath) {
    return [null, "/beacons", "/instances", "/observations", "/observations/stats"].includes(aclPath);
  },

  async list(groupId) {
    const { data } = await api.get(`/v3/${groupId}/tokens`);
    return data;
  },

  async update(groupId, id, { description, acl }) {
    const { data } = await api.put(`/v3/${groupId}/tokens/${id}`, {
      description,
      acl,
    });
    return data;
  },
};
