<template>
  <div class="chart">
    <div class="items">
      <div v-for="item in items" :key="item.date" class="item">
        <div class="bar-holder">
          <div class="label">
            {{ item.count }}
          </div>
          <div class="bar" :style="barStyles(item)"></div>
        </div>
        <div class="date">
          {{ date(item) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";

export default {
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  computed: {
    maxHeight() {
      return this.items.reduce((max, item) => Math.max(item.count, max), 0);
    },
  },

  methods: {
    barStyles(item) {
      return {
        height: `${this.height(item)}%`,
        opacity: item.count ? 1 : 0.25,
      };
    },

    height(item) {
      return (item.count / Math.max(this.maxHeight, 1)) * 100;
    },

    date(item) {
      return format(new Date(item.date), "MM-dd");
    },
  },
};
</script>

<style lang="scss" scoped>
.items {
  display: flex;
}

.item {
  flex: 1;
}

.item + .item {
  margin-left: 1rem;
}

.bar-holder {
  display: flex;
  flex-direction: column;
  height: 120px;
  justify-content: flex-end;
  position: relative;
}

.bar-holder > .label {
  font-size: 0.8rem;
  margin-bottom: 0.2rem;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bar-holder > .bar {
  background: $blue-dark;
  border-radius: 2px;
  min-height: 2px;
}

.date {
  color: $grey-dark;
  font-size: 0.8rem;
  margin-top: 0.3rem;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
