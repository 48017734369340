import api from "@/services/api";

export default {
  async invite(groupId) {
    const {
      data: { token },
    } = await api.post(`/v3/${groupId}/invite`);
    return token;
  },

  inviteGroup(token) {
    return api.post("/v3/invitegroup", { token });
  },

  join(token) {
    return api.post("/v3/join", { token });
  },

  joinCreate(token, user, password) {
    return api.post("/v3/joincreate", { token, user, password });
  },

  async list(groupId) {
    const { data } = await api.get(`/v3/${groupId}/users`);
    return data;
  },

  delete(groupId, username) {
    return api.delete(`/v3/${groupId}/users/${username}`);
  },

  async resetOwner(token) {
    const { data } = await api.post("/v3/resetowner", {
      token,
    });
    return data;
  },

  resetPassword(token, password) {
    return api.post("/v3/password-reset", {
      token,
      password,
    });
  },
};
