<template>
  <form v-on="$listeners">
    <slot />
  </form>
</template>

<script>
export default {
  methods: {
    scrollToFirstError() {
      const input = document.querySelector(".is-invalid input");
      if (!input) {
        return;
      }
      input.focus();
    },
  },
};
</script>
