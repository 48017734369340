<template>
  <div :class="classes" @click="toggle">
    <div class="track">
      <div class="knob" />
    </div>
    <div class="label">
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    value: {
      type: Boolean,
    },
  },

  computed: {
    classes() {
      return {
        switch: true,
        "is-active": this.value,
        "is-disabled": this.disabled,
      };
    },
  },

  methods: {
    toggle() {
      if (!this.disabled) {
        this.$emit("input", !this.value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.switch {
  align-items: center;
  cursor: pointer;
  display: flex;
  user-select: none;
}

.track {
  background: $grey-lighter;
  border: 1px solid $grey-light;
  border-radius: 0.5rem;
  height: 1rem;
  position: relative;
  transition: all 0.1s;
  width: 3rem;
}

.knob {
  background: $white;
  border: 1px solid $grey-light;
  border-radius: 0.7rem;
  box-shadow: 0 1px 3px rgba($grey, 0.25);
  height: 1.4rem;
  left: -0.2rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.1s ease-in-out;
  width: 1.4rem;
}

.label {
  margin-left: 1rem;
}

.is-active .track {
  background: $blue-lighter;
  border-color: $blue-light;
}

.is-active .knob {
  background: $blue-dark;
  border-color: $blue-dark;
  left: calc(100% - 1.2rem);
}

.is-disabled {
  cursor: not-allowed;
}

.is-disabled .track {
  opacity: 0.5;
}
</style>
