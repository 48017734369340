import api from "@/services/api";

export default {
  async list(groupId, params) {
    const { data } = await api.get(`/v3/${groupId}/observations`, {
      params: {
        page_size: 100000,
        ...params,
      },
    });
    return data;
  },

  async instances(groupId) {
    const { data } = await api.get(`/v3/${groupId}/observations/instances`);
    return data;
  },

  exportCsv(groupId, { config, instance }) {
    return api.get(`/v3/${groupId}/observations`, {
      params: {
        config,
        instance,
        page: 1,
        page_size: 2500000,
        format: "csv",
      },
    });
  },

  async test(groupId) {
    return await api.post(`/v3/${groupId}/observations/test`);
  },

  async summary(groupId) {
    const { data } = await api.get(`/v3/${groupId}/observations/summary`);
    return data;
  },
};
