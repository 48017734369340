<template>
  <div class="groups">
    <div class="nav">
      <a
        v-if="$t('_paths.manual')"
        class="item"
        :href="$t('_paths.manual')"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span class="icon">
          <font-awesome-icon :icon="icons.faBook" />
        </span>
        <span class="text">
          {{ $t("navigation.manual") }}
        </span>
      </a>
      <fu-dropdown>
        <template slot="trigger">
          <button class="item" type="button">
            <span class="icon">
              <font-awesome-icon :icon="icons.faUser" />
            </span>
            <span class="text">
              {{ username }}
            </span>
          </button>
        </template>
        <fu-menu class="user-menu">
          <fu-menu-item v-if="$auth.provider === 'internal'" to="/settings">
            {{ $t("navigation.settings") }}
          </fu-menu-item>
          <fu-menu-item @click="logOut">
            {{ $t("navigation.logOut") }}
          </fu-menu-item>
        </fu-menu>
      </fu-dropdown>
    </div>
    <div class="inner">
      <div class="logo">
        <img :src="require(`@/assets/images/${$t('_paths.logo')}`)" alt="" />
      </div>
      <div v-if="groups.length" class="group-picker">
        <div class="header">{{ $t("teams.chooseTeam") }}</div>
        <router-link
          v-for="group in groups"
          :key="group.id"
          class="item"
          :to="`/${group.id}/overview`"
        >
          <span class="name">{{ group.name }}</span>
          <font-awesome-icon :icon="icons.faArrowRight" />
        </router-link>
      </div>
      <fu-notification v-else :title="$t('teams.noTeamsTitle')">
        {{ $t("teams.noTeams") }}
      </fu-notification>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faArrowRight,
  faBars,
  faBook,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import FuDropdown from "@/components/Dropdown";
import FuMenu from "@/components/Menu";
import FuMenuItem from "@/components/MenuItem";
import FuNotification from "@/components/Notification";
import Unauthenticated from "@/layouts/Unauthenticated";
import auth from "@/services/auth";
import groups from "@/services/groups";

export default {
  inject: ["$auth"],

  route: {
    meta: {
      layout: Unauthenticated,
    },
  },

  components: {
    FontAwesomeIcon,
    FuDropdown,
    FuMenu,
    FuMenuItem,
    FuNotification,
  },

  async preload({ redirect, route }) {
    const groupList = await groups.list();
    if (groupList.length === 1) {
      return redirect(`/${groupList[0].id}/overview`);
    }
    const group =
      route.query.group &&
      groupList.find((group) => group.name === route.query.group);
    if (group) {
      return redirect(`/${group.id}/overview`);
    }
    return {
      groups: groupList,
    };
  },

  data() {
    return {
      changePassword: false,
      username: auth.user.username,
    };
  },

  created() {
    this.icons = {
      faArrowRight,
      faBars,
      faBook,
      faUser,
    };
  },

  methods: {
    async logOut() {
      await auth.logOut();
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
.groups {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

.inner {
  max-width: 360px;
  width: 100%;
}

.nav {
  display: flex;
  position: fixed;
  right: 1rem;
  top: 1.25rem;
}

.nav > * + * {
  margin-left: 1rem;
}

.nav .item {
  align-items: center;
  background: none;
  border: 0;
  color: $white;
  cursor: pointer;
  display: flex;
  height: 2rem;
  font: inherit;
  padding: 0.5rem;
  text-decoration: none;
}

.nav .item > .icon {
  margin-right: 1rem;
}

.logo {
  margin-bottom: 2.5rem;
}

.logo > img {
  display: block;
  margin: 0 auto;
  max-width: 280px;
}

.group-picker {
  background-color: $white;
  border-radius: 0.25rem;
  overflow: hidden;
}

.group-picker > .header {
  background-color: $grey-lighter;
  border-bottom: 1px solid $grey-light;
  color: $grey-darkest;
  padding: 0.75rem 1rem;
}

.group-picker > .item {
  align-items: center;
  color: $grey-darkest;
  display: flex;
  padding: 1rem;
  text-decoration: none;
}

.group-picker > .item > .name {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.group-picker > .item > svg {
  margin-left: 1rem;
  opacity: 0.5;
}

.group-picker > .item:hover {
  background-color: $blue-lightest;
  color: $blue-dark;
}

.group-picker > .item + .item {
  border-top: 1px solid $grey-light;
}
</style>
