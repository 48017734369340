<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
    },
  },

  computed: {
    classes() {
      return {
        container: true,
        [`is-${this.width}`]: !!this.width,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin: 0 auto;
  max-width: 1680px;
  width: 100%;
}

.is-narrow {
  max-width: 1024px;
}
</style>
