<template>
  <transition-group class="toast-center" name="toast" tag="div">
    <div class="aligner" v-for="toast in toasts" :key="toast.id">
      <fu-toast v-bind="toast" @dismiss="dismiss(toast.id)" />
    </div>
  </transition-group>
</template>

<script>
import FuToast from "@/components/Toast";
import { AppError } from "@/helpers/errors";

export default {
  components: {
    FuToast,
  },

  data() {
    return {
      nextId: 1,
      toasts: [],
    };
  },

  methods: {
    raise(message) {
      const id = this.nextId++;
      this.toasts.pop();
      this.toasts.push({ id, message, type: "message" });
      setTimeout(() => this.dismiss(id), 4000);
    },

    raiseError(error) {
      if (!error instanceof AppError) {
        throw new Error("Error must be an instance of AppError");
      }
      const id = this.nextId++;
      this.toasts.pop();
      this.toasts.push({
        id,
        message: error.message,
        details: error.details,
        type: "error",
      });
    },

    dismiss(id) {
      this.toasts = this.toasts.filter((t) => t.id !== id);
    },
  },
};
</script>

<style lang="scss" scoped>
.toast-enter-active {
  transition: all 0.5s ease;
}

.toast-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.toast-enter,
.toast-leave-to {
  opacity: 0;
  transform: translateY(100vh);
}

.toast-center {
  align-items: center;
  bottom: 2rem;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 100;
}

.aligner {
  bottom: 0;
  position: absolute;
}
</style>
