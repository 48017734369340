<template>
  <fu-container>
    <template v-if="beaconArray.length">
      <fu-base-view :actions="actions" :title="$t('beacons.title')">
        <div class="filters" slot="filters">
          <fu-text-input
            class="name-filter"
            v-model.trim="nameFilter"
            :placeholder="$t('beacons.beaconSearchPlaceholder')"
            @keydown.esc="nameFilter = null"
          />
          <fu-filterable-select
            v-if="!!ownerOptions.length"
            :placeholder="$t('beacons.userSearchPlaceholder')"
            :filter="ownerOptionFilter"
            :value="ownerFilter"
            :prefix="$t('beacons.ownerFilter')"
            :options="ownerOptions"
            @input="(owner) => (ownerFilter = owner)"
          >
            <template slot="value" slot-scope="{ value }">
              <template v-if="value">{{ value }}</template>
              <template v-else>{{ $t("beacons.any") }}</template>
            </template>
            <template slot="item" slot-scope="{ option }">
              <div>{{ option.label }}</div>
            </template>
            <template slot="empty-results" slot-scope="{ query }">
              {{ $t("beacons.ownerFilterNoResults", [query]) }}
            </template>
          </fu-filterable-select>
        </div>
        <div v-if="nameFilter || ownerFilter" class="filter-badges">
          <button
            v-if="nameFilter"
            class="filter-badge"
            @click="nameFilter = null"
          >
            {{ $t("beacons.nameFilter") }}:&nbsp;{{ nameFilter }}&nbsp;&times;
          </button>
          <button
            v-if="ownerFilter"
            class="filter-badge"
            @click="ownerFilter = null"
          >
            {{ $t("beacons.ownerFilter") }}:&nbsp;{{ ownerFilter }}&nbsp;&times;
          </button>
        </div>
        <fu-card>
          <fu-beacon-list :beacons="filteredBeacons" @download="openDownload" />
        </fu-card>
        <fu-download-dialog
          v-if="downloadedBeacon"
          :download="downloadBeacon"
          :instant="instantDownload"
          :target="downloadedBeacon.name"
          :version="latestVersion"
          @close="downloadedBeacon = null"
          @success="refresh"
        >
          {{ $t("beacons.downloadImmutable") }}
        </fu-download-dialog>
      </fu-base-view>
    </template>
    <template v-else>
      <fu-empty-state
        :actionText="$t('beacons.addBeacon')"
        :actionUrl="`/${$app.groupId}/beacons/new`"
        :title="$t('beacons.emptyTitle')"
        :icon="icons.faWifi"
      >
        {{ $t("beacons.emptyDescription") }}
      </fu-empty-state>
    </template>
  </fu-container>
</template>

<script>
import {
  faArrowDown,
  faTimes,
  faWifi,
} from "@fortawesome/free-solid-svg-icons";
import FuBaseView from "@/components/BaseView";
import FuBeaconList from "@/components/BeaconList";
import FuCard from "@/components/Card";
import FuContainer from "@/components/Container";
import FuDownloadDialog from "@/components/DownloadDialog";
import FuEmptyState from "@/components/EmptyState";
import FuFilterableSelect from "@/components/FilterableSelect";
import FuTextInput from "@/components/TextInput";
import { AppError } from "@/helpers/errors";
import auth from "@/services/auth";
import beacons from "@/services/beacons";
import instances from "@/services/instances";

export default {
  inject: ["$app", "$progress"],

  components: {
    FuBaseView,
    FuBeaconList,
    FuCard,
    FuContainer,
    FuDownloadDialog,
    FuEmptyState,
    FuFilterableSelect,
    FuTextInput,
  },

  async preload({ $app, route }) {
    const { name, owner } = route.query;
    return {
      beacons: await beacons.list($app.groupId),
      instances: await instances.list($app.groupId),
      latestVersion: await beacons.version($app.groupId),
      nameFilter: name ?? null,
      ownerFilter: owner ?? null,
    };
  },

  data() {
    return {
      downloadedBeacon: null,
      instantDownload: false,
      nameFilter: null,
      ownerFilter: null,
    };
  },

  created() {
    this.icons = { faArrowDown, faTimes, faWifi };
  },

  mounted() {
    window.addEventListener("keydown", this.onKeyDown);
    window.addEventListener("keyup", this.onKeyUp);
  },

  beforeDestroy() {
    window.removeEventListener("keydown", this.onKeyDown);
    window.removeEventListener("keyup", this.onKeyUp);
  },

  watch: {
    nameFilter(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateLocation();
      }
    },

    ownerFilter(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateLocation();
      }
    },
  },

  computed: {
    actions() {
      return [
        {
          handler: this.addBeacon,
          primary: true,
          text: this.$t("beacons.addBeacon"),
        },
      ];
    },

    beaconArray() {
      return Object.keys(this.beacons).map((name) => {
        const beacon = this.beacons[name];
        return {
          name,
          instances: this.instancesByBeacon[name],
          isUpToDate: this.isUpToDate(beacon),
          ...beacon,
        };
      });
    },

    filteredBeacons() {
      let beacons = this.beaconArray;
      if (this.nameFilter) {
        beacons = beacons.filter((beacon) => {
          return beacon.name.toLowerCase().indexOf(this.nameFilter) !== -1;
        });
      }
      if (this.ownerFilter) {
        beacons = beacons.filter((beacon) => {
          return beacon.owner
            ? beacon.owner.username.toLowerCase().indexOf(this.ownerFilter) !==
                -1
            : false;
        });
      }
      return beacons;
    },

    instancesByBeacon() {
      return this.instances.reduce(
        (acc, instance) => {
          const key = `${instance.config}${instance.name}`;
          if (!acc.seen[key]) {
            acc.seen[key] = true;
            acc.data[instance.config] = acc.data[instance.config] || [];
            acc.data[instance.config].push(instance);
          }
          return acc;
        },
        {
          seen: {},
          data: Object.keys(this.beacons).reduce((acc, beacon) => {
            acc[beacon] = [];
            return acc;
          }, {}),
        }
      ).data;
    },

    ownerOptions() {
      const me = auth.user.username;
      const owners = new Set(
        this.beaconArray
          .filter(({ owner }) => !!owner)
          .map(({ owner }) => owner.username)
      );
      const options = [...owners.keys()]
        .filter((username) => username !== me)
        .map((username) => ({
          key: username,
          value: username,
          label: username,
        }))
        .sort((a, b) => a.value.toLowerCase().localeCompare(b.value));
      if (owners.has(me)) {
        options.unshift({
          key: me,
          value: me,
          label: `${me} (${this.$t("beacons.ownerYou")})`,
        });
      }
      return options;
    },
  },

  methods: {
    addBeacon() {
      this.$router.push(`/${this.$app.groupId}/beacons/new`);
    },

    downloadBeacon(onProgress) {
      return beacons.download(
        this.$app.groupId,
        this.downloadedBeacon.name,
        onProgress
      );
    },

    onKeyDown(evt) {
      if (evt.key === "Shift") {
        this.instantDownload = true;
      }
    },

    onKeyUp(evt) {
      if (evt.key === "Shift") {
        this.instantDownload = false;
      }
    },

    async openDownload(beacon) {
      this.$progress.start();
      try {
        this.downloadedBeacon = await beacons.get(this.$app.groupId, beacon);
      } catch (error) {
        this.$toast.raiseError(
          AppError.create(this.$t("beacons.loadError"), error)
        );
      } finally {
        this.$progress.finish();
      }
    },

    async refresh() {
      this.$progress.start();
      this.beacons = await beacons.list(this.$app.groupId);
      this.$progress.finish();
    },

    isUpToDate(beacon) {
      const buildVersion = Number(beacon.buildVersion?.replace(".", "") || 0);
      const latestVersion = Number(this.latestVersion.replace(".", ""));

      return beacon.buildPlatform && beacon.buildTime
        ? buildVersion === latestVersion
        : false;
    },

    ownerOptionFilter(query, option) {
      return option.key.toLowerCase().includes(query.toLowerCase());
    },

    updateLocation() {
      const queryParams = new URLSearchParams();
      if (this.nameFilter) {
        queryParams.set("name", this.nameFilter);
      }
      if (this.ownerFilter) {
        queryParams.set("owner", this.ownerFilter);
      }
      const queryStr = queryParams.toString();
      window.history.replaceState(
        null,
        null,
        queryStr
          ? `/${this.$app.groupId}/beacons?${queryStr}`
          : `/${this.$app.groupId}/beacons`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.filters {
  display: flex;
}

.filters > * + * {
  margin-left: 0.5rem;
}

.filter-badges {
  margin-bottom: 1rem;
  margin-top: -0.5rem;
}

.filter-badges > * + * {
  margin-left: 0.5rem;
}

.filter-badge {
  align-items: center;
  background: $blue-dark;
  border: 0;
  border-radius: 3px;
  color: $white;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 0.9rem;
  padding: 0.25rem 0.5rem;
}

.name-filter {
  min-width: 15rem;
}
</style>
