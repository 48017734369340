import get from "lodash/get";
import { i18n } from "@/main";

export default function errorsFor($v, path, label) {
  const model = path ? get($v, path) : $v;
  const errors = [];
  if (!model.$dirty) {
    return errors;
  }
  if (model.alphanumeric === false) {
    errors.push(i18n.t("validations.alphanumeric", [label]));
  }
  if (model.beaconName === false) {
    errors.push(i18n.t("validations.beaconName", [label]));
  }
  if (model.domain === false) {
    errors.push(i18n.t("validations.domain", [label]));
  }
  if (model.httpOrHttps === false) {
    errors.push(i18n.t("validations.httpOrHttps", [label]));
  }
  if (model.https === false) {
    errors.push(i18n.t("validations.https", [label]));
  }
  if (model.metaKey === false) {
    errors.push(i18n.t("validations.metaKey", [label]));
  }
  if (model.metaValue === false) {
    errors.push(i18n.t("validations.metaValue", [label]));
  }
  if (model.ip === false) {
    errors.push(i18n.t("validations.ip", [label]));
  }
  if (model.ipv4 === false) {
    errors.push(i18n.t("validations.ipv4", [label]));
  }
  if (model.ipv6 === false) {
    errors.push(i18n.t("validations.ipv6", [label]));
  }
  if (model.maxLength === false) {
    errors.push(
      i18n.t("validations.maxLength", [label, model.$params.maxLength.max])
    );
  }
  if (model.password === false) {
    errors.push(i18n.t("validations.password", [label]));
  }
  if (model.required === false) {
    errors.push(i18n.t("validations.required", [label]));
  }
  if (model.sameAs === false) {
    errors.push(i18n.t("validations.sameAs", [label, model.$params.sameAs.eq]));
  }
  if (model.username === false) {
    errors.push(i18n.t("validations.username", [label]));
  }
  return errors;
}
