<template>
  <div class="dropdown">
    <div class="trigger" @click.stop="toggleOpen">
      <slot name="trigger" />
    </div>
    <transition name="popover">
      <div class="popover" v-if="isOpen" @click.stop="handleClick">
        <div class="content">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },

  mounted() {
    document.documentElement.addEventListener("click", this.close);
  },

  beforeDestroy() {
    document.documentElement.removeEventListener("click", this.close);
  },

  methods: {
    open() {
      this.isOpen = true;
    },

    close() {
      this.isOpen = false;
    },

    handleClick(evt) {
      if (evt.target.tagName === "BUTTON") {
        this.close();
      }
    },

    toggleOpen() {
      this.isOpen = !this.isOpen;
      this.$emit(this.isOpen ? "open" : "close");
    },
  },
};
</script>

<style lang="scss" scoped>
.popover-enter-active,
.popover-leave-active {
  transition: all 0.15s;
}

.popover-enter,
.popover-leave-to {
  opacity: 0;
  margin-top: 20px;
}

.dropdown {
  display: inline-flex;
  position: relative;
}

.popover {
  background: $white;
  border: 1px solid $grey-light;
  border-radius: 3px;
  box-shadow: 0px 2px 3px rgba($black, 0.1);
  margin: 0.25rem 0 0;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 1;
}

.popover > .content {
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
</style>
