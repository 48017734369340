<template>
  <fu-container>
    <template v-if="observations.meta.total">
      <fu-base-view :actions="actions" :title="$t('observations.title')">
        <div class="filters" slot="filters">
          <fu-filterable-select
            :placeholder="$t('observations.beaconSearchPlaceholder')"
            :filter="instanceOptionFilter"
            :value="instanceFilter"
            :prefix="$t('observations.beaconName')"
            :options="instanceOptions"
            @input="({ config, name }) => setInstanceFilter(config, name)"
          >
            <template slot="value" slot-scope="{ value }">
              <template v-if="value">
                {{ value.name }}
              </template>
              <template v-else>{{ $t("observations.any") }}</template>
            </template>
            <template slot="item" slot-scope="{ option }">
              <div>{{ option.value.name }}</div>
              <fu-instance-indicator
                v-if="option.value.name !== option.value.config"
              >
                {{ option.value.config }}
              </fu-instance-indicator>
            </template>
            <template slot="empty-results" slot-scope="{ query }">
              {{ $t("observations.beaconFilterNoResults", [query]) }}
            </template>
          </fu-filterable-select>
          <fu-button
            v-if="instanceFilter"
            class="filter"
            :icon="icons.faTimes"
            @click="clearInstanceFilter"
          >
            {{ $t("observations.clearFilter") }}
          </fu-button>
        </div>
        <fu-card class="observations">
          <div v-if="newItemsAvailable" class="observations-notification">
            {{ $t("observations.newItemsAvailable") }}
            &ndash;
            <button @click="refresh">
              {{ $t("observations.clickToRefresh") }}
            </button>
          </div>
          <fu-table
            align="top"
            :columns="columns"
            :data="observations.data"
            :page="Number(params.page)"
            :page-size="Number(params.page_size)"
            :page-sizes="[100, 500, 1000]"
            :page-count="Math.ceil(observations.meta.total / params.page_size)"
            :sort-field="params.sort_column"
            :sort-order="params.sort_direction"
            :total="observations.meta.total"
            @click-row="(o) => openObservation(o)"
            @update:page="(p) => setParam('page', p)"
            @update:page-size="(p) => setParam('page_size', p)"
            @update:sort-field="(p) => setParam('sort_column', p)"
            @update:sort-order="(p) => setParam('sort_direction', p)"
          >
            <template #column-timestamp="{ row }">
              {{ row.timestamp | timestamp }}
            </template>
            <template #column-beacon="{ row }">
              <div @click.stop>
                <router-link
                  :to="`/${$app.groupId}/observations?config=${row.configName}&instance=${row.beaconName}`"
                  >{{ row.beaconName }}</router-link
                >
              </div>
              <fu-instance-indicator v-if="row.beaconName !== row.configName">
                {{ row.configName }}
              </fu-instance-indicator>
            </template>
            <template #column-protocol="{ row }">
              <a
                :href="protocolUrl(row)"
                rel="noopener noreferrer"
                target="_blank"
                @click.stop
              >
                {{ protocol(row) }}
              </a>
            </template>
            <template #column-direction="{ row }">
              <span v-if="row.bidirectional == null" class="direction">
                <font-awesome-icon
                  class="direction-icon direction-icon-invisible"
                  :icon="icons.faArrowLeft"
                />
                <font-awesome-icon
                  class="direction-icon"
                  :icon="icons.faArrowRight"
                />
              </span>
              <span v-else-if="!row.bidirectional" class="direction">
                <font-awesome-icon
                  class="direction-icon direction-icon-faded"
                  :icon="icons.faArrowLeft"
                />
                <font-awesome-icon
                  class="direction-icon"
                  :icon="icons.faArrowRight"
                />
              </span>
              <span v-else-if="row.bidirectional" class="direction">
                <font-awesome-icon
                  class="direction-icon"
                  :icon="icons.faArrowLeft"
                />
                <font-awesome-icon
                  class="direction-icon"
                  :icon="icons.faArrowRight"
                />
              </span>
            </template>
          </fu-table>
        </fu-card>
      </fu-base-view>
    </template>
    <template v-else>
      <fu-empty-state
        :title="$t('observations.emptyTitle')"
        :icon="icons.faSmileBeam"
      >
        <div>{{ $t("observations.emptyDescription") }}</div>
        <div v-if="$i18n.locale === 'en'">
          <a
            href="/manual/alerts/overview/#retention-policy"
            target="_blank"
            rel="noreferrer noopener"
            >{{ $t("observations.retentionPolicy") }}</a
          >
        </div>
      </fu-empty-state>
    </template>
    <fu-drawer
      v-if="observation"
      title="Observation details"
      @close="observation = null"
    >
      <div
        v-for="(value, key) in observationDetails(observation)"
        :key="key"
        class="observation-detail"
      >
        <div class="observation-detail-key">
          {{ prettyObservationKey(key) }}
        </div>
        <div class="observation-detail-value">
          <template v-if="key === 'timestamp'">
            {{ value | timestamp }}&nbsp;{{ timeOffset() }}
          </template>
          <template v-else>
            {{ value }}
          </template>
        </div>
      </div>
    </fu-drawer>
  </fu-container>
</template>

<script>
import {
  faArrowLeft,
  faArrowUp,
  faArrowRight,
  faSmileBeam,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import FuBaseView from "@/components/BaseView";
import FuButton from "@/components/Button";
import FuCard from "@/components/Card";
import FuContainer from "@/components/Container";
import FuDrawer from "@/components/Drawer";
import FuEmptyState from "@/components/EmptyState";
import FuFilterableSelect from "@/components/FilterableSelect";
import FuInstanceIndicator from "@/components/InstanceIndicator";
import FuTable from "@/components/Table";
import timestamp from "@/filters/timestamp";
import { AppError } from "@/helpers/errors";
import timeOffset from "@/helpers/timeOffset";
import files from "@/services/files";
import instances from "@/services/instances";
import observations from "@/services/observations";

const NEW_ITEMS_CHECK_THRESHOLD_MS = 5000;

async function fetchLatestObservation(groupId, params = {}) {
  return (
    await observations.list(groupId, {
      ...params,
      page: 1,
      page_size: 1,
      sort_column: "timestamp",
      sort_direction: "desc",
    })
  ).data[0];
}

function withoutEmpty(object) {
  return Object.keys(object).reduce((result, key) => {
    if (object[key] != null && object[key] != "") {
      result[key] = object[key];
    }
    return result;
  }, {});
}

export default {
  inject: ["$app", "$progress", "$toast"],

  components: {
    FontAwesomeIcon,
    FuBaseView,
    FuButton,
    FuCard,
    FuContainer,
    FuDrawer,
    FuEmptyState,
    FuFilterableSelect,
    FuInstanceIndicator,
    FuTable,
  },

  filters: {
    timestamp,
  },

  async preload({ $app, route }) {
    const params = {
      config: null,
      instance: null,
      page: 1,
      page_size: 100,
      sort_column: null,
      sort_direction: null,
      ...route.query,
    };
    delete params.t;
    return {
      latestObservation: await fetchLatestObservation($app.groupId, params),
      instances: await instances.list($app.groupId),
      observations: await observations.list($app.groupId, params),
      params,
    };
  },

  data() {
    return {
      columns: [
        {
          field: "timestamp",
          label: `${this.$t("observationList.timestamp")} ${this.timeOffset()}`,
          sortable: true,
        },
        {
          field: "beacon",
          label: this.$t("observationList.beaconName"),
          sortable: true,
          width: "99%",
        },
        {
          field: "from",
          label: this.$t("observationList.from"),
          classes: {
            "from-column": true,
          },
        },
        {
          field: "direction",
          label: null,
        },
        {
          field: "to",
          label: this.$t("observationList.to"),
          classes: {
            "to-column": true,
          },
        },
        {
          field: "protocol",
          label: this.$t("observationList.protocol"),
        },
      ],
      exporting: false,
      newItemsAvailable: false,
      observation: null,
    };
  },

  created() {
    this.newItemsCheckInterval = setInterval(
      this.checkForNewItems,
      NEW_ITEMS_CHECK_THRESHOLD_MS
    );
    this.icons = {
      faArrowLeft,
      faArrowRight,
      faSmileBeam,
      faTimes,
    };
  },

  beforeDestroy() {
    clearInterval(this.newItemsCheckInterval);
  },

  watch: {
    params: {
      deep: true,
      handler(params) {
        this.$router.push({ query: withoutEmpty(params) });
      },
    },
  },

  computed: {
    actions() {
      return [
        {
          handler: this.exportCsv,
          icon: faArrowUp,
          loading: this.exporting,
          text: this.$t("observations.exportCsv"),
        },
      ];
    },

    csvFilename() {
      const { instance } = this.params;
      return instance ? `observations-${instance}.csv` : "observations.csv";
    },

    instanceFilter() {
      return this.instanceOptions.find(
        (o) =>
          o.value.config === this.params.config &&
          o.value.name === this.params.instance
      )?.value;
    },

    instanceOptions() {
      return this.instances
        .reduce(
          (acc, instance) => {
            const key = `${instance.config}${instance.name}`;
            if (!acc.seen[key]) {
              acc.seen[key] = true;
              acc.data.push(instance);
            }
            return acc;
          },
          { seen: {}, data: [] }
        )
        .data.sort((a, b) => {
          return a.name.localeCompare(b.name);
        })
        .map(({ config, name }) => ({
          key: `${config}${name}`,
          value: { config, name },
        }));
    },
  },

  methods: {
    timeOffset,

    clearInstanceFilter() {
      this.setParam("config", null);
      this.setParam("instance", null);
    },

    async checkForNewItems() {
      if (document.hidden) {
        return;
      }
      const latestObservation = await fetchLatestObservation(
        this.$app.groupId,
        this.params
      );
      if (!latestObservation) {
        return;
      }
      if (latestObservation.timestamp > this.latestObservation.timestamp) {
        this.newItemsAvailable = true;
        this.latestObservation = latestObservation;
        clearInterval(this.newItemsCheckInterval);
      }
    },

    async exportCsv() {
      this.exporting = true;
      try {
        const csv = await observations.exportCsv(
          this.$app.groupId,
          withoutEmpty(this.params)
        );
        files.download(csv, this.csvFilename);
      } catch (error) {
        this.$toast.raiseError(
          AppError.create(this.$t("observations.exportError"), error)
        );
      } finally {
        this.exporting = false;
      }
    },

    instanceOptionFilter(query, option) {
      return option.key.toLowerCase().includes(query.toLowerCase());
    },

    observationDetails(observation) {
      return Object.keys(observation).reduce((details, key) => {
        if (observation[key] != null) {
          details[key] = observation[key];
        }
        return details;
      }, {});
    },

    openObservation(observation) {
      this.observation = observation;
    },

    prettyObservationKey(key) {
      return key
        .split(/(?=[A-Z])/)
        .join(" ")
        .toUpperCase();
    },

    protocol({ port, protocol }) {
      return [port, protocol]
        .filter((p) => (protocol === "IPProto" ? true : !!p))
        .join("/");
    },

    protocolUrl({ protocol }) {
      let section = "";
      if (/Broadcast/.test(protocol)) {
        section = "broadcast";
      } else if (/IPProto/.test(protocol)) {
        section = "ip-payload-protocols";
      } else if (/SpoofIP/.test(protocol)) {
        section = "spoof-ip";
      } else {
        section = protocol.toLowerCase();
      }

      return `/manual/beacon/escape_methods#${section}`;
    },

    refresh() {
      this.setParam("page", 1);
      this.setParam("t", Date.now());
      if (!this.newItemsCheckInterval) {
        this.newItemsCheckInterval = setInterval(
          this.checkForNewItems,
          NEW_ITEMS_CHECK_THRESHOLD_MS
        );
      }
    },

    setInstanceFilter(config, name) {
      this.setParam("config", config);
      this.setParam("instance", name);
    },

    setParam(param, value) {
      // Jump back to page 1 when other than page & sort params change.
      const params = { ...this.params };
      if (!param.match(/^(page|sort_)/)) {
        params.page = 1;
      }
      params[param] = value;
      this.params = params;
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-state ::v-deep .icon {
  font-size: 4rem;
}

.filters {
  display: flex;
}

.filters > * + * {
  margin-left: 0.5rem;
}

.observations {
  position: relative;
}

.observations-notification {
  background-color: $blue-darker;
  color: $white;
  padding: 1rem 1.5rem;
}

.observations-notification button {
  appearance: none;
  background: none;
  border: 0;
  color: $white;
  cursor: pointer;
  font: inherit;
  text-decoration: underline;
}

.observation-detail {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.observation-detail-key {
  color: $grey-darker;
}

::v-deep .from-column.head-cell,
::v-deep .from-column.body-cell .body-cell-content-inner {
  padding-right: 0;
}

::v-deep .to-column.head-cell,
::v-deep .to-column.body-cell .body-cell-content-inner {
  padding-left: 0;
}

.direction-icon {
  color: $grey-darker;
}

.direction-icon + .direction-icon {
  margin-left: 0.5rem;
}

.direction-icon-faded {
  color: $grey-light;
}

.direction-icon-invisible {
  visibility: hidden;
}

@media screen and (min-width: 768px) {
  .observation-detail {
    display: flex;
    margin-bottom: 0.25rem;
  }

  .observation-detail-key {
    flex-shrink: 0;
    width: 33.33%;
  }

  .observation-detail-value {
    flex: 1;
  }
}

@media screen and (max-width: 767px) {
  .empty-state ::v-deep .icon {
    font-size: 4.5rem;
  }
}
</style>
