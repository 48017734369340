<template>
  <div :class="classes">
    <div v-if="label" class="label">
      {{ label }}
    </div>
    <div class="input-group">
      <input
        ref="input"
        class="input"
        :value="value"
        v-bind="$attrs"
        v-on="{
          ...$listeners,
          input: (event) => $emit('input', event.target.value),
          blur: (event) => {
            if (trim && !!event.target.value) {
              $emit('input', event.target.value.trim());
            }
            $emit('blur', event);
          },
        }"
      />
      <slot name="append" />
    </div>
    <div v-if="invalid" class="error">
      <div class="icon">
        <font-awesome-icon :icon="icons.faExclamationCircle" />
      </div>
      <div class="body">
        {{ errors[0] }}
      </div>
    </div>
    <div v-if="$slots.help" class="help">
      <div class="icon">
        <font-awesome-icon :icon="icons.faQuestionCircle" />
      </div>
      <div class="body">
        <slot name="help" />
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faExclamationCircle,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

export default {
  inheritAttrs: false,

  components: {
    FontAwesomeIcon,
  },

  props: {
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
    label: {
      type: String,
    },
    trim: {
      type: Boolean,
      default() {
        return this.$attrs.type !== "password";
      },
    },
    value: {
      type: [Number, String],
    },
  },

  created() {
    this.icons = {
      faExclamationCircle,
      faQuestionCircle,
    };
  },

  computed: {
    classes() {
      return {
        "text-input": true,
        "is-invalid": this.invalid,
        "is-disabled":
          this.$attrs.disabled !== false && this.$attrs.disabled !== undefined,
      };
    },

    invalid() {
      return !!this.errors.length;
    },
  },

  methods: {
    blur() {
      this.$refs.input.blur();
    },

    copy() {
      this.$refs.input.select();
      document.execCommand("copy");
    },

    focus() {
      this.$refs.input.focus();
    },

    select() {
      this.$refs.input.select();
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  margin-bottom: 0.25rem;
}

.input {
  background: $white;
  border: 1px solid $grey-light;
  border-radius: 3px;
  display: block;
  font: inherit;
  padding: 0.5rem 1rem;
  width: 100%;
}

.input:focus {
  border-color: $blue;
  box-shadow: 0 0 0 3px $blue-lighter;
  outline: 0;
}

.input-group {
  align-items: center;
  display: flex;
}

.input-group .input:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.input-group .button:last-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.input-group > :not(:first-child) {
  margin-left: -1px;
}

.error {
  display: flex;
  font-size: 0.9rem;
  margin-top: 0.25rem;
}

.error > .icon {
  margin-right: 0.25rem;
}

.help {
  color: $grey-dark;
  display: flex;
  font-size: 0.9rem;
  margin-top: 0.25rem;
}

.help > .icon {
  margin-right: 0.25rem;
}

.is-disabled .input {
  background: $grey-lightest;
  color: $grey-darker;
  cursor: not-allowed;
}

.is-invalid {
  color: $red-dark;
}

.is-invalid .input {
  border-color: $red-dark;
}

.is-invalid .input:focus {
  box-shadow: 0 0 0 3px $red-lightest;
}
</style>
