<template>
  <fu-form ref="form" @submit.prevent="submit">
    <fu-annotated-section :title="$t('homeForm.general')">
      <fu-card>
        <fu-card-section>
          <fu-text-input
            :label="$t('homeForm.name')"
            :errors="errorsFor($v.config.name, $t('homeForm.name'))"
            v-model="$v.config.name.$model"
          >
            <template slot="help">
              {{ $t("homeForm.nameHelp") }}
            </template>
          </fu-text-input>
        </fu-card-section>
      </fu-card>
    </fu-annotated-section>
    <fu-annotated-section :title="$t('homeForm.notifications')">
      <fu-card>
        <fu-card-section>
          <fu-stack>
            <fu-stack-item>
              <fu-text-input
                v-model="$v.config.slack.$model"
                :label="$t('homeForm.slack')"
                :errors="errorsFor($v.config.slack, $t('homeForm.slack'))"
              >
                <template slot="help">
                  {{ $t("homeForm.slackHelp") }}
                </template>
              </fu-text-input>
            </fu-stack-item>
            <fu-stack-item>
              <fu-text-input
                v-model="$v.config.webhook.$model"
                :label="$t('homeForm.webhook')"
                :errors="errorsFor($v.config.webhook, $t('homeForm.webhook'))"
              >
                <template slot="help">
                  {{ $t("homeForm.webhookHelp") }}
                </template>
              </fu-text-input>
            </fu-stack-item>
            <fu-stack-item>
              <fu-button
                primary
                @click="sendTestObservation"
                :loading="sendingTest"
              >
                {{ $t("homeForm.sendTestObservation") }}
              </fu-button>
            </fu-stack-item>
          </fu-stack>
        </fu-card-section>
      </fu-card>
    </fu-annotated-section>
    <fu-annotated-section>
      <fu-button primary type="submit" :loading="saving">
        {{ $t("homeForm.save") }}
      </fu-button>
    </fu-annotated-section>
  </fu-form>
</template>

<script>
import { faArrowUp, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { validationMixin } from "vuelidate";
import { maxLength, required } from "vuelidate/lib/validators";
import FuAnnotatedSection from "@/components/AnnotatedSection";
import FuButton from "@/components/Button";
import FuCard from "@/components/Card";
import FuCardSection from "@/components/CardSection";
import FuForm from "@/components/Form";
import FuStack from "@/components/Stack";
import FuStackItem from "@/components/StackItem";
import FuTextInput from "@/components/TextInput";
import { AppError } from "@/helpers/errors";
import errorsMixin from "@/mixins/errors";
import home from "@/services/home";
import observations from "@/services/observations";
import alphanumeric from "@/validators/alphanumeric";
import httpOrHttps from "@/validators/httpOrHttps";
import https from "@/validators/https";

function config(data) {
  return {
    name: null,
    slack: null,
    webhook: null,
    ...data,
  };
}

export default {
  inject: ["$app", "$toast"],

  components: {
    FuAnnotatedSection,
    FuButton,
    FuCard,
    FuCardSection,
    FuForm,
    FuStack,
    FuStackItem,
    FuTextInput,
  },

  mixins: [errorsMixin, validationMixin],

  props: {
    initialConfig: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      config: config(this.initialConfig),
      saving: false,
      sendingTest: false,
    };
  },

  validations: {
    config: {
      name: {
        alphanumeric,
        maxLength: maxLength(32),
        required,
      },
      slack: {
        https,
      },
      webhook: {
        httpOrHttps,
      },
    },
  },

  created() {
    this.icons = {
      faArrowUp,
      faTrashAlt,
    };
  },

  watch: {
    initialConfig(newConfig) {
      this.config = config(newConfig);
    },
  },

  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$nextTick(() => this.$refs.form.scrollToFirstError());
        return;
      }
      this.saving = true;
      try {
        await home.update(this.$app.groupId, this.config);
        this.$toast.raise(this.$t("homeForm.saveSuccess"));
      } catch (error) {
        this.$toast.raiseError(
          AppError.create(this.$t("homeForm.saveError"), error)
        );
      } finally {
        this.saving = false;
      }
    },

    async sendTestObservation() {
      this.sendingTest = true;
      try {
        await observations.test(this.$app.groupId);
        this.$toast.raise(this.$t("homeForm.sendTestObservationSuccess"));
      } catch (error) {
        this.$toast.raiseError(
          AppError.create(this.$t("homeForm.sendTestObservationError"), error)
        );
      } finally {
        this.sendingTest = false;
      }
    },
  },
};
</script>
