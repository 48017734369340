<template>
  <div :class="classes" v-tooltip="{ content: tooltip, classes: ['tooltip'] }">
    <div>{{ protocol }}</div>
  </div>
</template>

<script>
import { differenceInHours } from "date-fns";
import timeOffset from "@/helpers/timeOffset";
import relativeTime from "@/filters/relativeTime";
import timestamp from "@/filters/timestamp";

export default {
  filters: {
    relativeTime,
    timestamp,
  },

  props: {
    protocol: {
      type: String,
    },
    timestamp: {
      type: Date,
    },
  },

  computed: {
    classes() {
      return ["observation-badge", `freshness-${this.freshness}`];
    },

    freshness() {
      const hoursAgo = differenceInHours(new Date(), new Date(this.timestamp));
      if (hoursAgo < 6) {
        return 1;
      } else if (hoursAgo < 2 * 24) {
        return 2;
      } else if (hoursAgo < 14 * 24) {
        return 3;
      }
      return 4;
    },

    tooltip() {
      return `${timestamp(this.timestamp)} ${timeOffset()}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.observation-badge {
  background: $blue;
  border-radius: 3px;
  color: $white;
  display: inline-block;
  font-size: 0.8rem;
  padding: 0.1rem 0.4rem;
}

.freshness-1 {
  background: $blue-darker;
  color: $white;
}

.freshness-2 {
  background: $blue-dark;
  color: $white;
}

.freshness-3 {
  background: $blue;
  color: $white;
}

.freshness-4 {
  background: $blue-lighter;
  color: $blue-darker;
}
</style>
