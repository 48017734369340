<template>
  <div class="toast" :class="{ 'is-error': type === 'error' }">
    <div class="body">
      <div>{{ message }}</div>
      <div v-if="details" class="details">
        {{ details }}
      </div>
    </div>
    <button class="close" type="button" @click="$emit('dismiss')">
      &times;
    </button>
  </div>
</template>

<script>
export default {
  props: {
    details: {
      type: String,
    },
    message: {
      type: String,
    },
    type: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.toast {
  background: rgba($black, 0.9);
  border-radius: 0.25rem;
  color: $white;
  display: inline-flex;
  font-size: 1.1rem;
  margin-top: 1rem;
  max-width: 30rem;
  padding: 0.75rem 3rem 0.75rem 1rem;
  position: relative;
  transition: all 0.25s;
}

.details {
  color: $red-lighter;
  font-size: 0.8em;
}

.is-error {
  background: $red;
}

.close {
  align-items: center;
  appearance: none;
  background: none;
  border: 0;
  color: inherit;
  cursor: pointer;
  display: flex;
  font-family: inherit;
  font-size: 1.4rem;
  height: 3.2rem;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 3rem;
}
</style>
