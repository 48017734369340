<template>
  <div class="card">
    <div v-if="title || $slots.header" class="header">
      <h2 v-if="title" class="title">{{ title }}</h2>
      <slot name="header" />
    </div>
    <div class="body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background: $white;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(63, 65, 68, 0.1),
    0 1px 3px 0 rgba(63, 65, 68, 0.15);
  overflow: hidden;
}

.header {
  display: flex;
  padding: 1.5rem 1.5rem 0;
}

.title {
  flex: 1;
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;
}
</style>
