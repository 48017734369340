<template>
  <fu-container width="narrow">
    <fu-base-view
      :actions="actions"
      :title="$t('beacons.editBeacon', [$route.params.id])"
    >
      <fu-beacon-form
        :editable="editable"
        :initial-config="initialConfig"
        :save="save"
        @after-save="afterSave"
      />
    </fu-base-view>
    <fu-delete-dialog
      v-if="deletedBeacon"
      :target="beaconId"
      :delete="deleteBeacon"
      @close="deletedBeacon = null"
      @success="$router.push(`/${$app.groupId}/beacons`)"
    >
      <template v-if="seenInstances.length" #default="{ target }">
        <i18n path="beacons.deleteWarning" tag="div" class="warning">
          <strong>{{ target }}</strong>
        </i18n>
        <div v-if="seenInstances" class="last-seen-instances">
          <h3>{{ $t("beaconList.seenInstances", [seenInstances.length]) }}</h3>
          <ul>
            <li v-for="instance in seenInstances" :key="instance.name">
              <router-link :to="instance.url">{{ instance.name }}</router-link>
              ({{ instance.lastSeen | timestamp }})
            </li>
          </ul>
        </div>
      </template>
    </fu-delete-dialog>
    <fu-download-dialog
      v-if="downloadedBeacon"
      :download="downloadBeacon"
      :instant="instantDownload"
      :target="downloadedBeacon.name"
      :version="latestVersion"
      @close="downloadedBeacon = null"
    >
      <fu-stack>
        <fu-stack-item>
          {{ $t("beacons.downloadImmutable") }}
        </fu-stack-item>
      </fu-stack>
    </fu-download-dialog>
  </fu-container>
</template>

<script>
import {
  faArrowDown,
  faCopy,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import FuBaseView from "@/components/BaseView";
import FuBeaconForm from "@/components/BeaconForm";
import FuContainer from "@/components/Container";
import FuDeleteDialog from "@/components/DeleteDialog";
import FuDownloadDialog from "@/components/DownloadDialog";
import FuStack from "@/components/Stack";
import FuStackItem from "@/components/StackItem";
import timestamp from "@/filters/timestamp";
import beacons from "@/services/beacons";
import instances from "@/services/instances";
import { AppError } from "@/helpers/errors";

export default {
  inject: ["$app", "$progress", "$toast"],

  components: {
    FuBaseView,
    FuBeaconForm,
    FuContainer,
    FuDeleteDialog,
    FuDownloadDialog,
    FuStack,
    FuStackItem,
  },

  filters: {
    timestamp,
  },

  async preload({ $app, route }) {
    return {
      beacons: await beacons.list($app.groupId),
      initialConfig: await beacons.get($app.groupId, route.params.id),
      instances: await instances.list($app.groupId),
      latestVersion: await beacons.version($app.groupId),
    };
  },

  data() {
    return {
      deletedBeacon: null,
      downloadedBeacon: null,
      instantDownload: false,
    };
  },

  mounted() {
    window.addEventListener("keydown", this.onKeyDown);
    window.addEventListener("keyup", this.onKeyUp);
  },

  beforeDestroy() {
    window.removeEventListener("keydown", this.onKeyDown);
    window.removeEventListener("keyup", this.onKeyUp);
  },

  computed: {
    actions() {
      return [
        {
          handler: this.openDownload,
          icon: faArrowDown,
          text: this.$t("beacons.download"),
        },
        {
          handler: this.clone,
          icon: faCopy,
          text: this.$t("beacons.clone"),
        },
        {
          handler: this.openDelete,
          icon: faTrashAlt,
          text: this.$t("beacons.delete"),
        },
      ];
    },

    beaconId() {
      return this.$route.params.id;
    },

    cloneUrl() {
      return `/${this.$app.groupId}/beacons/${this.beaconId}/clone`;
    },

    editable() {
      return !this.beacons[this.beaconId].buildVersion;
    },

    seenInstances() {
      return this.instances
        .filter((instance) => {
          return instance.config === this.beaconId;
        })
        .reduce(
          (uniq, instance) => {
            if (uniq.seen[instance.name]) {
              return uniq;
            }
            uniq.seen[instance.name] = true;
            uniq.data.push({
              name: instance.name,
              url: `/${this.$app.groupId}/observations?config=${instance.config}&instance=${instance.name}`,
              lastSeen: instance.lastSeen,
            });
            return uniq;
          },
          {
            seen: {},
            data: [],
          }
        ).data;
    },

    title() {
      return this.$route.params.title;
    },
  },

  methods: {
    clone() {
      this.$router.push(this.cloneUrl);
    },

    deleteBeacon() {
      return beacons.delete(this.$app.groupId, this.beaconId);
    },

    downloadBeacon(onProgress) {
      return beacons.download(
        this.$app.groupId,
        this.downloadedBeacon.name,
        onProgress
      );
    },

    onKeyDown(evt) {
      if (evt.key === "Shift") {
        this.instantDownload = true;
      }
    },

    onKeyUp(evt) {
      if (evt.key === "Shift") {
        this.instantDownload = false;
      }
    },

    openDelete() {
      this.deletedBeacon = this.beaconId;
    },

    async openDownload() {
      this.$progress.start();
      try {
        this.downloadedBeacon = await beacons.get(
          this.$app.groupId,
          this.beaconId
        );
      } catch (error) {
        this.$toast.raiseError(
          AppError.create(this.$t("beacons.loadError"), error)
        );
      } finally {
        this.$progress.finish();
      }
    },

    save(config) {
      return this.editable
        ? beacons.update(this.$app.groupId, this.beaconId, config)
        : beacons.updateMeta(this.$app.groupId, this.beaconId, config.meta);
    },

    afterSave(config) {
      if (config.name !== this.initialConfig.name) {
        this.$router.replace(
          `/${this.$app.groupId}/beacons/${config.name}/edit`
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.warning {
  background: #fffff0;
  border: 1px solid #ecc94b;
  border-radius: 3px;
  color: #975a16;
  padding: 0.6rem 1rem;
}

.last-seen-instances > h3 {
  font-size: 1.1rem;
  font-weight: 500;
  margin: 1.5rem 0 0;
}

.last-seen-instances > ul {
  margin: 0.5rem 0 0;
  padding: 0 0 0 2rem;
}
</style>
