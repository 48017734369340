<template>
  <fu-table :columns="columns" :data="users">
    <template #column-actions="{ row }">
      <fu-button-group>
        <fu-button
          :icon="icons.faTrashAlt"
          size="small"
          @click="$emit('delete', row.username)"
        />
      </fu-button-group>
    </template>
  </fu-table>
</template>

<script>
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import FuButton from "@/components/Button";
import FuButtonGroup from "@/components/ButtonGroup";
import FuTable from "@/components/LocalTable";

export default {
  components: {
    FuButton,
    FuButtonGroup,
    FuTable,
  },

  props: {
    users: {
      type: Array,
    },
  },

  data() {
    return {
      columns: [
        {
          field: "username",
          label: this.$t("userList.username"),
          width: "99%",
        },
        {
          field: "actions",
          label: this.$t("userList.actions"),
        },
      ],
    };
  },

  created() {
    this.icons = {
      faTrashAlt,
    };
  },
};
</script>
