<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    gap: {
      type: String,
      default: "medium",
    },
    row: {
      type: Boolean,
    },
  },

  computed: {
    classes() {
      return {
        stack: true,
        ["is-gap-" + this.gap]: true,
        "is-row": this.row,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.stack {
  margin: -1rem 0 0 !important;
}

.stack ::v-deep .stack-item {
  padding: 1rem 0 0;
}

.is-row {
  display: flex;
  margin: 0 0 0 -1rem !important;
}

.is-row ::v-deep .stack-item {
  padding: 0 0 0 1rem;
}

.is-row.is-gap-small {
  margin: 0 0 0 -0.5rem !important;
}

.is-row.is-gap-small ::v-deep .stack-item {
  padding: 0 0 0 0.5rem;
}
</style>
