<template>
  <div class="login">
    <div class="inner">
      <fu-login-form />
    </div>
  </div>
</template>

<script>
import FuLoginForm from "@/components/LoginForm";
import Unauthenticated from "@/layouts/Unauthenticated";

export default {
  route: {
    meta: {
      layout: Unauthenticated,
    },
  },

  components: {
    FuLoginForm,
  },
};
</script>

<style lang="scss" scoped>
.login {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

.inner {
  max-width: 360px;
  width: 100%;
}
</style>
