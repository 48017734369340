<template>
  <fu-container width="narrow">
    <fu-base-view :title="$t('configure.home')">
      <fu-home-form
        :initial-config="initialConfig"
        @after-import="afterImport"
      />
    </fu-base-view>
  </fu-container>
</template>

<script>
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import FuBaseView from "@/components/BaseView";
import FuContainer from "@/components/Container";
import FuHomeForm from "@/components/HomeForm";
import home from "@/services/home";

export default {
  inject: ["$app", "$progress"],

  components: {
    FuBaseView,
    FuContainer,
    FuHomeForm,
  },

  async preload({ $app }) {
    return {
      initialConfig: await home.get($app.groupId),
    };
  },

  data() {
    return {
      icons: { faTrashAlt },
    };
  },

  methods: {
    async afterImport() {
      this.$progress.start();
      this.initialConfig = await home.get(this.$app.groupId);
      this.$progress.finish();
    },
  },
};
</script>
