<template>
  <div :class="classes">
    <div class="overlay" @click="$emit('hide')" />
    <div class="panel">
      <fu-dropdown class="team-select-dropdown" v-if="groups.length > 1">
        <template slot="trigger">
          <button class="team-select" type="button">
            <span class="team-label">{{ $t("navigation.currentTeam") }}</span>
            <span class="team-name-row">
              <span class="team-name">{{ currentGroup.name }}</span>
              <svg
                class="icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                />
              </svg>
            </span>
          </button>
        </template>
        <fu-menu>
          <fu-menu-item
            v-for="group in groups"
            :key="group.id"
            :to="`/${group.id}/overview`"
          >
            {{ group.name }}
          </fu-menu-item>
        </fu-menu>
      </fu-dropdown>
      <div class="inner">
        <div class="items">
          <router-link
            :to="`/${$app.groupId}/overview`"
            active-class="is-active"
          >
            <span class="icon">
              <font-awesome-icon :icon="icons.faList" />
            </span>
            <span class="text">
              {{ $t("navigation.overview") }}
            </span>
          </router-link>
          <router-link
            :to="`/${$app.groupId}/beacons`"
            active-class="is-active"
          >
            <span class="icon">
              <font-awesome-icon :icon="icons.faWifi" />
            </span>
            <span class="text">
              {{ $t("navigation.beacons") }}
            </span>
          </router-link>
          <router-link
            :to="`/${$app.groupId}/observations`"
            active-class="is-active"
          >
            <span class="icon">
              <font-awesome-icon :icon="icons.faEye" />
            </span>
            <span class="text">
              {{ $t("navigation.observations") }}
            </span>
          </router-link>
          <a
            class="hide-large"
            href="/manual"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span class="icon">
              <font-awesome-icon :icon="icons.faBook" />
            </span>
            <span class="text">
              {{ $t("navigation.manual") }}
            </span>
          </a>
        </div>
        <div class="items">
          <div class="label">{{ $t("navigation.configure") }}</div>
          <router-link
            :to="`/${$app.groupId}/config/home`"
            active-class="is-active"
          >
            <span class="icon">
              <font-awesome-icon :icon="icons.faCog" />
            </span>
            <span class="text">
              {{ $t("navigation.home") }}
            </span>
          </router-link>
          <router-link
            :to="`/${$app.groupId}/config/users`"
            active-class="is-active"
          >
            <span class="icon">
              <font-awesome-icon :icon="icons.faUsers" />
            </span>
            <span class="text">
              {{ $t("navigation.users") }}
            </span>
          </router-link>
          <router-link
            :to="`/${$app.groupId}/config/tokens`"
            active-class="is-active"
          >
            <span class="icon">
              <font-awesome-icon :icon="icons.faKey" />
            </span>
            <span class="text">
              {{ $t("navigation.tokens") }}
            </span>
          </router-link>
        </div>
        <div v-if="$i18n.locale === 'en'" class="items is-bottom">
          <a
            href="https://sensorfu.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span class="icon">
              <font-awesome-icon :icon="icons.faGlobe" />
            </span>
            <span class="text">SensorFu</span>
            <span class="icon">
              <font-awesome-icon :icon="icons.faExternalLinkAlt" />
            </span>
          </a>
          <a href="/manual/eula" target="_blank" rel="noopener noreferrer">
            <span class="icon">
              <font-awesome-icon :icon="icons.faFileAlt" />
            </span>
            <span class="text">EULA</span>
            <span class="icon">
              <font-awesome-icon :icon="icons.faExternalLinkAlt" />
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faBook,
  faCog,
  faExternalLinkAlt,
  faEye,
  faFileAlt,
  faGlobe,
  faList,
  faLock,
  faKey,
  faSignOutAlt,
  faUsers,
  faWifi,
} from "@fortawesome/free-solid-svg-icons";
import FuDropdown from "@/components/Dropdown";
import FuMenu from "@/components/Menu";
import FuMenuItem from "@/components/MenuItem";

export default {
  inject: ["$app"],

  components: {
    FontAwesomeIcon,
    FuDropdown,
    FuMenu,
    FuMenuItem,
  },

  props: {
    currentGroupId: {
      type: String,
    },
    groups: {
      type: Array,
    },
    visible: {
      type: Boolean,
    },
  },

  data() {
    return {
      icons: {
        faBook,
        faCog,
        faEye,
        faExternalLinkAlt,
        faFileAlt,
        faGlobe,
        faList,
        faLock,
        faKey,
        faSignOutAlt,
        faUsers,
        faWifi,
      },
    };
  },

  watch: {
    $route() {
      this.$emit("hide");
    },
  },

  computed: {
    classes() {
      return {
        sidebar: true,
        "is-visible": this.visible,
      };
    },

    currentGroup() {
      return this.groups.length > 1
        ? this.groups.find((g) => g.id === this.currentGroupId)
        : null;
    },
  },

  methods: {
    onChangePassword() {
      this.$emit("change-password");
      this.$emit("hide");
    },
  },
};
</script>

<style lang="scss" scoped>
.panel {
  background: $white;
  border-right: 1px solid $grey-light;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  top: 4.5rem;
  width: 15rem;
  z-index: 2;
}

.team-select-dropdown {
  display: block;
}

.team-select-dropdown ::v-deep .popover {
  left: 0.5rem;
  right: 0.5rem;
}

.team-select {
  align-items: center;
  appearance: none;
  background: $white;
  border: 1px solid $grey-light;
  border-width: 0 0 1px;
  color: $grey-darkest;
  cursor: pointer;
  display: block;
  font: inherit;
  padding: 1.25rem 1.5rem;
  text-align: left;
  width: 100%;
}

.team-select:focus,
.team-select:hover {
  background: $grey-lighter;
}

.team-select .team-label {
  color: $grey-darker;
  display: block;
  font-size: 0.8rem;
  margin-bottom: 0.2rem;
  text-transform: uppercase;
}

.team-select .team-name-row {
  align-items: center;
  display: flex;
}

.team-select .team-name {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.team-select .icon {
  flex-shrink: 0;
  height: 1.2rem;
  margin-left: 0.25rem;
  width: 1.2rem;
}

.inner {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  padding: 1.25rem 0;
}

.items + .items {
  margin-top: 1.25rem;
}

.items > a {
  align-items: center;
  color: $grey-darkest;
  display: flex;
  padding: 0.5rem 1.5rem;
  text-align: left;
  text-decoration: none;
}

.items > a > .icon {
  color: $grey-dark;
  margin-right: 1rem;
  text-align: center;
  width: 1.5rem;
}

.items > a > .text {
  flex: 1;
}

.items > a > .text + .icon {
  color: $grey;
  margin-right: 0;
  text-align: right;
}

.items > a.is-active {
  color: $blue-dark;
  font-weight: 500;
}

.items > a.is-active > .icon {
  color: $blue-dark;
}

.items > .label {
  color: $grey-dark;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.5rem 1.5rem;
  text-transform: uppercase;
}

.items.is-bottom {
  margin-top: auto;
  padding-top: 2rem;
}

@media screen and (max-width: 767px) {
  .overlay {
    background: rgba($black, 0.5);
    bottom: 0;
    cursor: pointer;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: all 0.25s;
    visibility: hidden;
    z-index: 1;
  }

  .panel {
    border-right: 0;
    max-width: 18rem;
    opacity: 0;
    transform: translateX(-8rem);
    transition: all 0.25s;
    visibility: hidden;
    width: 100%;
  }

  .inner {
    padding: 1rem 0;
  }

  .items + .items:not(.is-bottom) {
    margin-top: 1rem;
  }

  .is-visible .overlay {
    opacity: 1;
    visibility: visible;
  }

  .is-visible .panel {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
  }
}
</style>
