var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"settings"},[_c('div',{staticClass:"nav"},[_c('router-link',{staticClass:"brand",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require(`@/assets/images/${_vm.$t('_paths.logo')}`),"alt":""}})]),_c('router-link',{staticClass:"go-back",attrs:{"to":_vm.prevRoute}},[_c('font-awesome-icon',{attrs:{"icon":_vm.icons.faArrowLeft}}),_c('span',[_vm._v(_vm._s(_vm.$t("settings.goBack")))])],1)],1),_c('div',{staticClass:"inner"},[_c('h1',{staticClass:"page-title"},[_vm._v(_vm._s(_vm.$t("settings.title")))]),_c('fu-card',{staticClass:"section"},[_c('h2',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.$t("changePasswordForm.title")))]),_c('fu-form',{on:{"submit":function($event){$event.preventDefault();return _vm.changePassword.apply(null, arguments)}}},[_c('fu-stack',[_c('fu-stack-item',[_c('fu-text-input',{attrs:{"label":_vm.$t('changePasswordForm.password'),"errors":_vm.errorsFor(
                  _vm.$v.changePasswordForm.password,
                  _vm.$t('changePasswordForm.password')
                ),"type":"password"},on:{"blur":() => _vm.$v.changePasswordForm.password.$touch(),"input":() => _vm.$v.changePasswordForm.password.$reset()},scopedSlots:_vm._u([(!_vm.$v.changePasswordForm.password.$error)?{key:"help",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("changePasswordForm.passwordHelp"))+" ")]},proxy:true}:null],null,true),model:{value:(_vm.$v.changePasswordForm.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.changePasswordForm.password, "$model", $$v)},expression:"$v.changePasswordForm.password.$model"}})],1),_c('fu-stack-item',[_c('fu-text-input',{attrs:{"label":_vm.$t('changePasswordForm.repeatPassword'),"errors":_vm.errorsFor(
                  _vm.$v.changePasswordForm.confirmation,
                  _vm.$t('changePasswordForm.repeatPassword')
                ),"type":"password"},model:{value:(_vm.$v.changePasswordForm.confirmation.$model),callback:function ($$v) {_vm.$set(_vm.$v.changePasswordForm.confirmation, "$model", $$v)},expression:"$v.changePasswordForm.confirmation.$model"}})],1),_c('fu-stack-item',[_c('fu-button',{attrs:{"disabled":_vm.$v.changePasswordForm.$invalid,"full-width":"","loading":_vm.loading,"primary":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("changePasswordForm.update"))+" ")])],1)],1)],1)],1),_c('fu-card',{staticClass:"section"},[_c('h2',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.$t("deleteAccountForm.title")))]),_c('i18n',{attrs:{"path":"deleteAccountForm.warning","tag":"p"}}),_c('fu-form',{on:{"submit":function($event){$event.preventDefault();return _vm.deleteAccount.apply(null, arguments)}}},[_c('fu-stack',[_c('fu-stack-item',[_c('fu-text-input',{attrs:{"type":"password","errors":_vm.errorsFor(
                  _vm.$v.deleteAccountForm.password,
                  _vm.$t('deleteAccountForm.password')
                )},scopedSlots:_vm._u([(!_vm.$v.deleteAccountForm.password.$error)?{key:"help",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("deleteAccountForm.passwordHelp"))+" ")]},proxy:true}:null],null,true),model:{value:(_vm.$v.deleteAccountForm.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.deleteAccountForm.password, "$model", $$v)},expression:"$v.deleteAccountForm.password.$model"}})],1),_c('fu-stack-item',[_c('fu-button',{attrs:{"destructive":"","disabled":_vm.$v.deleteAccountForm.$invalid,"full-width":"","type":"submit"}},[_vm._v(_vm._s(_vm.$t("deleteAccountForm.submit")))])],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }