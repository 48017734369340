<template>
  <fu-dialog
    :title="$t('downloadDialog.title', [target])"
    @close="$emit('close')"
  >
    <slot />
    <fu-progress-overlay
      v-if="message"
      :message="message"
      :progress="progress"
    />
    <template slot="actions">
      <fu-button-group>
        <fu-button @click="$emit('close')">
          {{ $t("downloadDialog.cancel") }}
        </fu-button>
        <fu-button primary @click="confirm">
          {{ $t("downloadDialog.download", [version]) }}
        </fu-button>
      </fu-button-group>
    </template>
  </fu-dialog>
</template>

<script>
import FuButton from "@/components/Button";
import FuButtonGroup from "@/components/ButtonGroup";
import FuDialog from "@/components/Dialog";
import FuProgressOverlay from "@/components/ProgressOverlay";
import files from "@/services/files";
import { AppError } from "@/helpers/errors";

export default {
  inject: ["$toast"],

  components: {
    FuButton,
    FuButtonGroup,
    FuDialog,
    FuProgressOverlay,
  },

  props: {
    download: {
      type: Function,
    },
    instant: {
      type: Boolean,
    },
    target: {
      type: String,
    },
    version: {
      type: String,
    },
  },

  data() {
    return {
      message: null,
      progress: 0,
    };
  },

  mounted() {
    if (this.instant) {
      this.confirm();
    }
  },

  methods: {
    close() {
      this.$emit("close");
    },

    async confirm() {
      try {
        this.message = this.$t("downloadDialog.processing");
        const image = await this.download(this.onProgress);
        files.download(image);
        this.$emit("success");
      } catch (error) {
        this.$toast.raiseError(
          AppError.create(this.$t("downloadDialog.error"), error)
        );
        this.$emit("error");
      } finally {
        this.message = null;
        this.$emit("close");
      }
    },

    onProgress(evt) {
      this.message = this.$t("downloadDialog.downloading");
      this.progress = (evt.loaded / evt.total) * 100;
    },
  },
};
</script>
