<template>
  <fu-dialog :title="title" :width="dialogWidth" @close="$emit('close')">
    <fu-token-form
      v-if="!createdToken"
      :beacons="beacons"
      :submit="createToken"
      @error="onError"
      @success="onSuccess"
    >
      <template #actions="{ vuelidate, loading }">
        <fu-button-group>
          <fu-button @click="$emit('close')">
            {{ $t("tokens.cancel") }}
          </fu-button>
          <fu-button
            primary
            :disabled="vuelidate.$invalid"
            :loading="loading"
            type="submit"
          >
            {{ $t("tokens.create") }}
          </fu-button>
        </fu-button-group>
      </template>
    </fu-token-form>
    <template v-else>
      <p>
        {{ $t("tokens.copyHelp") }}.
        <a href="/manual/alerts/api/" rel="noopener noreferrer">{{
          $t("tokens.apiHelp")
        }}</a>
      </p>
      <fu-stack>
        <fu-stack-item>
          <fu-text-input
            ref="token"
            :label="$t('tokens.token')"
            v-model="createdToken.token"
            readonly
          >
            <template slot="append">
              <fu-button primary :icon="icons.faCopy" @click="copyToken">
                {{ $t("tokens.copy") }}
              </fu-button>
            </template>
          </fu-text-input>
        </fu-stack-item>
        <fu-stack-item v-if="tokenEndpoint">
          <fu-text-input
            ref="endpoint"
            :label="$t('tokens.apiEndpoint')"
            :value="tokenEndpoint"
            readonly
          >
            <template slot="append">
              <fu-button primary :icon="icons.faCopy" @click="copyEndpoint">
                {{ $t("tokens.copy") }}
              </fu-button>
            </template>
          </fu-text-input>
        </fu-stack-item>
      </fu-stack>
    </template>
  </fu-dialog>
</template>

<script>
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import FuButton from "@/components/Button";
import FuButtonGroup from "@/components/ButtonGroup";
import FuDialog from "@/components/Dialog";
import FuStack from "@/components/Stack";
import FuStackItem from "@/components/StackItem";
import FuTextInput from "@/components/TextInput";
import { AppError } from "@/helpers/errors";
import tokens from "@/services/tokens";
import FuTokenForm from "./TokenForm";

export default {
  inject: ["$app", "$toast"],

  components: {
    FuButton,
    FuButtonGroup,
    FuDialog,
    FuStack,
    FuStackItem,
    FuTextInput,
    FuTokenForm,
  },

  props: {
    beacons: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      createdToken: null,
    };
  },

  created() {
    this.icons = { faCopy };
  },

  computed: {
    dialogWidth() {
      return this.createdToken ? null : "narrow";
    },

    title() {
      return this.createdToken
        ? this.$t("tokens.created")
        : this.$t("tokens.create");
    },

    tokenEndpoint() {
      if (!this.createdToken.path) {
        return;
      }
      const path =
        {
          "/beacons": "/beacons/<beacon-name>",
        }[this.createdToken.path] || this.createdToken.path;

      return `${window.location.origin}/api/token/v1${path}`;
    },
  },

  methods: {
    copyEndpoint() {
      this.$refs.endpoint.copy();
      this.$toast.raise(this.$t("tokens.copiedToClipboard"));
    },

    copyToken() {
      this.$refs.token.copy();
      this.$toast.raise(this.$t("tokens.copiedToClipboard"));
    },

    async createToken(token) {
      return await tokens.create(this.$app.groupId, token);
    },

    onError(error) {
      this.$toast.raiseError(
        AppError.create(this.$t("tokens.createError"), error)
      );
    },

    onSuccess(token) {
      this.createdToken = token;
      this.$toast.raise(this.$t("tokens.createSuccess"));
      this.$emit("after-save");
    },
  },
};
</script>

<style lang="scss" scoped>
.warning {
  background: #fffff0;
  border: 1px solid #ecc94b;
  border-radius: 3px;
  color: #975a16;
  padding: 0.5rem 1rem;
}

.warning > .icon {
  margin-right: 0.2rem;
}
</style>
