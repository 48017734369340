<template>
  <div class="base-view">
    <div class="header">
      <div class="title">
        {{ title }}
      </div>
      <div class="filters" v-if="$slots.filters">
        <slot name="filters" />
      </div>
      <div class="actions" v-if="actions.length">
        <fu-button-group class="hide-small">
          <fu-button
            v-for="(action, index) in actions"
            :key="index"
            :icon="action.icon"
            :loading="action.loading"
            :primary="action.primary"
            @click="action.handler"
          >
            {{ action.text }}
          </fu-button>
        </fu-button-group>
        <fu-dropdown class="hide-large">
          <template slot="trigger">
            <fu-button :icon="icons.faAngleDown">Actions</fu-button>
          </template>
          <fu-menu>
            <fu-menu-item
              v-for="(action, index) in actions"
              :key="index"
              @click="action.handler"
            >
              {{ action.text }}
            </fu-menu-item>
          </fu-menu>
        </fu-dropdown>
      </div>
    </div>
    <div class="body">
      <slot />
    </div>
  </div>
</template>

<script>
import { faAngleDown, faStream } from "@fortawesome/free-solid-svg-icons";
import FuButton from "@/components/Button";
import FuButtonGroup from "@/components/ButtonGroup";
import FuDropdown from "@/components/Dropdown";
import FuMenu from "@/components/Menu";
import FuMenuItem from "@/components/MenuItem";

export default {
  components: {
    FuButton,
    FuButtonGroup,
    FuDropdown,
    FuMenu,
    FuMenuItem,
  },

  props: {
    actions: {
      type: Array,
      default() {
        return [];
      },
    },
    title: {
      type: String,
    },
  },

  created() {
    this.icons = {
      faAngleDown,
      faStream,
    };
  },
};
</script>

<style lang="scss" scoped>
.base-view {
  margin: 0 auto;
  padding: 2rem 2.5rem;
}

.header {
  align-items: center;
  display: flex;
  height: 3rem;
  margin-bottom: 2rem;
}

.header > .filters {
  margin-right: 1rem;
}

.title {
  flex: 1;
  font-size: 1.5rem;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen and (max-width: 1023px) {
  .base-view {
    padding: 1.5rem;
  }

  .header {
    flex-wrap: wrap;
    height: auto;
  }

  .header > .actions {
    margin-left: auto;
  }

  .header > .filters {
    flex: 100%;
    margin: 1rem 0 0;
    order: 1;
  }
}
</style>
