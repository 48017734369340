import axios from "axios";

export class AppError extends Error {
  static create(message, originalError) {
    if (axios.isAxiosError(originalError)) {
      return new AppError(
        message,
        `HTTP status: ${originalError.response.status}`
      );
    }
    return new AppError(message);
  }

  constructor(message, details) {
    super(message);
    this._details = details;
  }

  get details() {
    return this._details;
  }
}
