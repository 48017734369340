<template>
  <fu-form @submit.prevent="submit">
    <div class="logo">
      <router-link to="/">
        <img :src="require(`@/assets/images/${$t('_paths.logo')}`)" alt="" />
      </router-link>
    </div>
    <fu-notification v-if="!failed && !success">
      <i18n path="resetPasswordForm.resetPassword" tag="div">
        <strong>{{ username }}</strong>
      </i18n>
    </fu-notification>
    <fu-notification
      v-if="failed"
      status="alert"
      :title="$t('resetPasswordForm.errorTitle')"
    >
      {{ $t("resetPasswordForm.error") }}
    </fu-notification>
    <fu-notification
      v-if="success"
      status="success"
      :title="$t('resetPasswordForm.successTitle')"
    >
      <i18n path="resetPasswordForm.success" tag="p">
        <router-link :to="`/login?username=${username}`">{{
          $t("resetPasswordForm.login")
        }}</router-link>
      </i18n>
    </fu-notification>
    <fu-card v-if="!success">
      <fu-card-section>
        <fu-stack>
          <fu-stack-item>
            <fu-text-input
              :label="$t('resetPasswordForm.password')"
              :errors="errorsFor($v.password, $t('resetPasswordForm.password'))"
              type="password"
              v-model="$v.password.$model"
              @blur="() => $v.password.$touch()"
              @input="() => $v.password.$reset()"
            >
              <template v-if="!$v.password.$error" #help>
                {{ $t("resetPasswordForm.passwordHelp") }}
              </template>
            </fu-text-input>
          </fu-stack-item>
          <fu-stack-item>
            <fu-text-input
              :label="$t('resetPasswordForm.repeatPassword')"
              :errors="
                errorsFor(
                  $v.passwordConfirmation,
                  $t('resetPasswordForm.repeatPassword')
                )
              "
              type="password"
              v-model="$v.passwordConfirmation.$model"
            />
          </fu-stack-item>
          <fu-stack-item>
            <fu-button
              full-width
              primary
              :disabled="$v.$invalid"
              :loading="loading"
              type="submit"
            >
              {{ $t("resetPasswordForm.submit") }}
            </fu-button>
          </fu-stack-item>
        </fu-stack>
      </fu-card-section>
    </fu-card>
  </fu-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, sameAs } from "vuelidate/lib/validators";
import FuButton from "@/components/Button";
import FuCard from "@/components/Card";
import FuCardSection from "@/components/CardSection";
import FuForm from "@/components/Form";
import FuNotification from "@/components/Notification";
import FuStack from "@/components/Stack";
import FuStackItem from "@/components/StackItem";
import FuTextInput from "@/components/TextInput";
import errorsMixin from "@/mixins/errors";
import users from "@/services/users";
import password from "@/validators/password";

export default {
  components: {
    FuButton,
    FuCard,
    FuCardSection,
    FuForm,
    FuNotification,
    FuStack,
    FuStackItem,
    FuTextInput,
  },

  mixins: [errorsMixin, validationMixin],

  props: {
    token: {
      type: String,
    },
    username: {
      type: String,
    },
  },

  data() {
    return {
      failed: false,
      loading: false,
      password: null,
      passwordConfirmation: null,
      success: false,
    };
  },

  validations: {
    password: {
      required,
      password,
    },
    passwordConfirmation: {
      required,
      sameAs: sameAs("password"),
    },
  },

  methods: {
    async submit() {
      this.failed = false;
      this.loading = true;
      try {
        await users.resetPassword(this.token, this.password);
        this.success = true;
      } catch {
        this.failed = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  background: $blue-darkest;
  margin-bottom: 2.5rem;
}

.logo > a {
  display: block;
  margin: 0 auto;
  max-width: 280px;
}

.logo > a > img {
  display: block;
  max-width: 100%;
}
</style>
