<template>
  <div class="join">
    <div class="inner">
      <fu-join-form :mode="$route.query.mode" :group="group" :token="token" />
    </div>
  </div>
</template>

<script>
import FuJoinForm from "@/components/JoinForm";
import { AppError } from "@/helpers/errors";
import Unauthenticated from "@/layouts/Unauthenticated";
import auth from "@/services/auth";
import users from "@/services/users";

export default {
  route: {
    meta: {
      layout: Unauthenticated,
    },
  },

  components: {
    FuJoinForm,
  },

  async preload({ route, redirect, $auth, $i18n, $toast }) {
    const currentUser = auth.user;
    if (!currentUser && $auth.provider !== "internal") {
      $toast.raiseError(new AppError($i18n.t("joinForm.loginRequired")));
      return redirect("/");
    }
    const { token } = route.query;
    let inviteGroup;
    try {
      inviteGroup = await users.inviteGroup(token);
    } catch (err) {
      $toast.raiseError(new AppError($i18n.t("joinForm.invalidToken")));
      return redirect("/");
    }
    if (currentUser && route.query.mode !== "join") {
      return redirect({
        path: "/join",
        query: { ...route.query, mode: "join" },
      });
    }
    return {
      currentUser,
      group: inviteGroup.data.name,
    };
  },

  beforeRouteEnter(to, from, next) {
    if (!to.query.token) {
      next("/login");
    } else {
      next();
    }
  },

  computed: {
    token() {
      return decodeURIComponent(this.$route.query.token);
    },
  },
};
</script>

<style lang="scss" scoped>
.join {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

.inner {
  max-width: 360px;
  width: 100%;
}
</style>
