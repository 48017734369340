<template>
  <fu-table
    :columns="columns"
    :data="tokens"
    :page.sync="page"
    :page-size.sync="pageSize"
    :page-sizes="[100, 500, 1000]"
    :sort-field.sync="sortField"
    :sort-order.sync="sortOrder"
    @click-row="(row) => $emit('edit', row)"
  >
    <template #column-paths="{ row }">
      <v-popover
        v-if="row.acl.paths.length > 1"
        popover-base-class="tooltip"
        trigger="hover"
      >
        <div class="tooltip-trigger">
          {{ $t("tokens.pathsCount", [row.acl.paths.length]) }}
        </div>
        <template slot="popover">
          <ul class="tooltip-list">
            <li v-for="path in row.acl.paths" :key="path">
              <span>{{ path }}</span>
            </li>
          </ul>
        </template>
      </v-popover>
      <template v-else-if="row.acl.paths.length">
        {{ row.acl.paths[0] }}
      </template>
      <template v-else>
        {{ $t("tokens.allPaths") }}
      </template>
    </template>
    <template #column-owner="{ row }">
      <template v-if="row.owner && row.owner.username">
        {{ row.owner.username }}
      </template>
    </template>
    <template #column-beacons="{ row }">
      <v-popover
        v-if="row.acl.beacons.length > 1"
        popover-base-class="tooltip"
        trigger="hover"
      >
        <div class="tooltip-trigger">
          {{ $t("tokens.beaconsCount", [row.acl.beacons.length]) }}
        </div>
        <template slot="popover">
          <ul class="tooltip-list">
            <li v-for="beacon in row.acl.beacons" :key="beacon">
              <span>{{ beacon }}</span>
            </li>
          </ul>
        </template>
      </v-popover>
      <template v-else-if="row.acl.beacons.length">
        {{ row.acl.beacons[0] }}
      </template>
      <template v-else>
        {{ $t("tokens.allBeacons") }}
      </template>
    </template>
    <template #column-lastseen="{ row }">
      <div v-if="row.lastseen">
        {{ row.lastseen | timestamp }}
      </div>
    </template>
    <template #column-actions="{ row }">
      <fu-button-group>
        <fu-button
          :icon="icons.faTrashAlt"
          size="small"
          @click.stop="$emit('delete', row)"
        />
      </fu-button-group>
    </template>
  </fu-table>
</template>

<script>
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import FuButton from "@/components/Button";
import FuButtonGroup from "@/components/ButtonGroup";
import FuTable from "@/components/LocalTable";
import timestamp from "@/filters/timestamp";
import timeOffset from "@/helpers/timeOffset";

export default {
  components: {
    FuButton,
    FuButtonGroup,
    FuTable,
  },

  filters: {
    timestamp,
  },

  props: {
    tokens: {
      type: Array,
    },
  },

  data() {
    return {
      columns: [
        {
          field: "description",
          label: this.$t("tokens.description"),
          sortable: true,
          width: "99%",
        },
        {
          field: "owner",
          label: this.$t("tokens.owner"),
          sortable: true,
        },
        {
          field: "paths",
          label: this.$t("tokens.paths"),
        },
        {
          field: "beacons",
          label: this.$t("tokens.beacons"),
        },
        {
          field: "lastseen",
          label: `${this.$t("tokens.lastSeen")} ${timeOffset()}`,
          sortable: true,
        },
        {
          field: "actions",
          label: this.$t("tokens.actions"),
        },
      ],
      page: 1,
      pageSize: 100,
      sortField: null,
      sortOrder: null,
    };
  },

  created() {
    this.icons = { faTrashAlt };
  },
};
</script>

<style lang="scss" scoped>
.beacons {
  max-width: 320px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tooltip-trigger {
  color: $grey-darker;
  text-decoration: underline;
}

.tooltip-list {
  list-style-position: inside;
  margin: 0;
  padding: 0;
}

.tooltip-list > li > span {
  margin-left: -0.5rem;
}
</style>
