<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout";

export default {
  computed: {
    layout() {
      let layout = DefaultLayout;
      this.$route.matched.forEach((route) => {
        layout = route.meta.layout || layout;
      });
      return layout;
    },
  },
};
</script>

<style lang="scss">
html {
  box-sizing: border-box;
  color: $black;
  font: 16px/1.5 "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background: $grey-lightest;
  margin: 0;
}

a {
  color: $blue-dark;
}
</style>
