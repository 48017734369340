<template>
  <fu-dialog :title="$t('tokens.edit')" width="narrow" @close="$emit('close')">
    <fu-token-form
      :beacons="beacons"
      :initial-token="token"
      :submit="updateToken"
      @error="onError"
      @success="onSuccess"
    >
      <template #actions="{ vuelidate, loading }">
        <fu-button-group>
          <fu-button @click="$emit('close')">
            {{ $t("tokens.cancel") }}
          </fu-button>
          <fu-button
            primary
            :disabled="vuelidate.$invalid"
            :loading="loading"
            type="submit"
          >
            {{ $t("tokens.update") }}
          </fu-button>
        </fu-button-group>
      </template>
    </fu-token-form>
  </fu-dialog>
</template>

<script>
import FuButton from "@/components/Button";
import FuButtonGroup from "@/components/ButtonGroup";
import FuDialog from "@/components/Dialog";
import { AppError } from "@/helpers/errors";
import tokens from "@/services/tokens";
import FuTokenForm from "./TokenForm";

export default {
  inject: ["$app", "$toast"],

  components: {
    FuButton,
    FuButtonGroup,
    FuDialog,
    FuTokenForm,
  },

  props: {
    beacons: {
      type: Array,
      default() {
        return [];
      },
    },
    token: {
      type: Object,
      required: true,
    },
  },

  methods: {
    async updateToken(token) {
      return await tokens.update(this.$app.groupId, this.token.id, token);
    },

    onError(error) {
      this.$toast.raiseError(
        AppError.create(this.$t("tokens.updateError"), error)
      );
    },

    onSuccess() {
      this.$toast.raise(this.$t("tokens.updateSuccess"));
      this.$emit("after-save");
    },
  },
};
</script>

<style lang="scss" scoped>
.warning {
  background: #fffff0;
  border: 1px solid #ecc94b;
  border-radius: 3px;
  color: #975a16;
  padding: 0.5rem 1rem;
}

.warning > .icon {
  margin-right: 0.2rem;
}
</style>
