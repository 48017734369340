<template>
  <div>
    <div class="logo">
      <router-link to="/">
        <img :src="require(`@/assets/images/${$t('_paths.logo')}`)" alt="" />
      </router-link>
    </div>
    <fu-notification v-if="!success && !failed">
      <i18n
        :path="
          mode === 'create' ? 'joinForm.createIntro' : 'joinForm.joinIntro'
        "
        tag="div"
      >
        <strong>{{ group }}</strong>
      </i18n>
    </fu-notification>
    <fu-notification
      v-if="failed"
      status="alert"
      :title="$t('joinForm.errorTitle')"
    >
      {{ $t("joinForm.error") }}
    </fu-notification>
    <template v-if="mode === 'join'">
      <fu-form @submit.prevent="join">
        <div class="actions">
          <fu-button full-width primary :loading="loading" type="submit">
            {{ $t("joinForm.join") }}
          </fu-button>
          <fu-button full-width to="/" type="button">
            {{ $t("joinForm.cancel") }}
          </fu-button>
        </div>
      </fu-form>
    </template>
    <template v-else-if="mode === 'create'">
      <fu-notification
        v-if="success"
        status="success"
        :title="$t('joinForm.welcome', [username])"
      >
        <i18n path="joinForm.success" tag="div">
          <router-link :to="redirectTo">{{ $t("joinForm.login") }}</router-link>
        </i18n>
      </fu-notification>
      <fu-form @submit.prevent="createJoin">
        <fu-card v-if="!success">
          <fu-card-section>
            <fu-stack>
              <fu-stack-item>
                <fu-text-input
                  :label="$t('joinForm.username')"
                  :errors="errorsFor($v.username, $t('joinForm.username'))"
                  v-model="$v.username.$model"
                  @blur="() => $v.username.$touch()"
                  @input="() => $v.username.$reset()"
                >
                  <template slot="help">
                    {{ $t("joinForm.usernameHelp") }}
                  </template>
                </fu-text-input>
              </fu-stack-item>
              <fu-stack-item>
                <fu-text-input
                  :label="$t('joinForm.password')"
                  :errors="errorsFor($v.password, $t('joinForm.password'))"
                  type="password"
                  v-model="$v.password.$model"
                  @blur="() => $v.password.$touch()"
                  @input="() => $v.password.$reset()"
                >
                  <template v-if="!$v.password.$error" #help>
                    {{ $t("joinForm.passwordHelp") }}
                  </template>
                </fu-text-input>
              </fu-stack-item>
              <fu-stack-item>
                <fu-text-input
                  :label="$t('joinForm.repeatPassword')"
                  :errors="
                    errorsFor(
                      $v.passwordConfirmation,
                      $t('joinForm.repeatPassword')
                    )
                  "
                  type="password"
                  v-model="$v.passwordConfirmation.$model"
                />
              </fu-stack-item>
              <fu-stack-item>
                <fu-button
                  full-width
                  primary
                  :disabled="$v.$invalid"
                  :loading="loading"
                  type="submit"
                >
                  {{ $t("joinForm.join") }}
                </fu-button>
              </fu-stack-item>
            </fu-stack>
          </fu-card-section>
        </fu-card>
      </fu-form>
      <div v-if="!success" class="or-actions">
        <router-link
          :to="{ path: '/join', query: { ...$route.query, mode: 'login' } }"
        >
          Join with an existing account
        </router-link>
      </div>
    </template>
    <template v-else-if="mode === 'login'">
      <fu-form @submit.prevent="loginJoin">
        <fu-card v-if="!success">
          <fu-card-section>
            <fu-stack>
              <fu-stack-item>
                <fu-text-input
                  :label="$t('joinForm.username')"
                  :errors="errorsFor($v.username, $t('joinForm.username'))"
                  v-model="$v.username.$model"
                  @blur="() => $v.username.$touch()"
                  @input="() => $v.username.$reset()"
                />
              </fu-stack-item>
              <fu-stack-item>
                <fu-text-input
                  :label="$t('joinForm.password')"
                  :errors="errorsFor($v.password, $t('joinForm.password'))"
                  type="password"
                  v-model="$v.password.$model"
                  @blur="() => $v.password.$touch()"
                  @input="() => $v.password.$reset()"
                />
              </fu-stack-item>
              <fu-stack-item>
                <fu-button
                  full-width
                  primary
                  :disabled="$v.$invalid"
                  :loading="loading"
                  type="submit"
                >
                  {{ $t("joinForm.join") }}
                </fu-button>
              </fu-stack-item>
            </fu-stack>
          </fu-card-section>
        </fu-card>
      </fu-form>
      <div class="or-actions">
        <router-link
          :to="{ path: '/join', query: { ...$route.query, mode: 'create' } }"
        >
          {{ $t("joinForm.createJoin") }}
        </router-link>
      </div>
    </template>
    <template v-else>
      <div class="actions">
        <fu-button
          :to="{ path: '/join', query: { ...$route.query, mode: 'create' } }"
          full-width
          primary
        >
          {{ $t("joinForm.createJoin") }}
        </fu-button>
        <fu-button
          :to="{ path: '/join', query: { ...$route.query, mode: 'login' } }"
          full-width
        >
          {{ $t("joinForm.loginJoin") }}
        </fu-button>
      </div>
    </template>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, sameAs } from "vuelidate/lib/validators";
import FuButton from "@/components/Button";
import FuCard from "@/components/Card";
import FuCardSection from "@/components/CardSection";
import FuForm from "@/components/Form";
import FuNotification from "@/components/Notification";
import FuStack from "@/components/Stack";
import FuStackItem from "@/components/StackItem";
import FuTextInput from "@/components/TextInput";
import errorsMixin from "@/mixins/errors";
import auth from "@/services/auth";
import users from "@/services/users";
import password from "@/validators/password";
import username from "@/validators/username";

export default {
  components: {
    FuButton,
    FuCard,
    FuCardSection,
    FuForm,
    FuNotification,
    FuStack,
    FuStackItem,
    FuTextInput,
  },

  mixins: [errorsMixin, validationMixin],

  props: {
    group: {
      type: String,
    },
    mode: {
      type: String,
    },
    token: {
      type: String,
    },
  },

  data() {
    return {
      failed: false,
      loading: false,
      password: null,
      passwordConfirmation: null,
      success: null,
      username: null,
    };
  },

  validations() {
    if (this.mode === "login") {
      return {
        username: {
          required,
          username,
        },
        password: {
          required,
        },
      };
    } else if (this.mode === "create") {
      return {
        username: {
          required,
          username,
        },
        password: {
          required,
          password,
        },
        passwordConfirmation: {
          required,
          sameAs: sameAs("password"),
        },
      };
    }
    return {};
  },

  computed: {
    redirectTo() {
      return {
        path: "/login",
        query: { username: this.username },
      };
    },
  },

  methods: {
    async createJoin() {
      this.failed = false;
      this.loading = true;
      try {
        await users.joinCreate(this.token, this.username, this.password);
        this.success = true;
      } catch {
        this.failed = true;
      } finally {
        this.loading = false;
      }
    },

    async join() {
      this.failed = false;
      this.loading = true;
      try {
        await users.join(this.token, this.username);
        return this.$router
          .replace({
            path: "/",
            query: { group: this.group },
          })
          .catch(() => {});
      } catch (error) {
        this.failed = true;
      } finally {
        this.loading = false;
      }
    },

    async loginJoin() {
      this.failed = false;
      this.loading = true;
      try {
        await auth.logIn(this.username, this.password);
        await users.join(this.token, this.username);
        return this.$router
          .replace({
            path: "/",
            query: { group: this.group },
          })
          .catch(() => {});
      } catch {
        this.failed = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  background: $blue-darkest;
  margin-bottom: 2.5rem;
}

.logo > a {
  display: block;
  margin: 0 auto;
  max-width: 280px;
}

.logo > a > img {
  display: block;
  max-width: 100%;
}

.actions > .button + .button {
  margin-top: 0.5rem;
}

.or-actions {
  margin-top: 1rem;
  text-align: center;
}

.or-actions > a {
  color: $blue-light;
}
</style>
