<template>
  <div v-if="ready" class="default-layout">
    <fu-navbar
      :username="auth.user.username"
      @change-password="changePassword = true"
      @log-out="logOut"
      @toggle-menu="sidebarVisible = !sidebarVisible"
    />
    <fu-sidebar
      :current-group-id="$app.groupId"
      :groups="groups"
      :visible="sidebarVisible"
      @change-password="changePassword = true"
      @hide="sidebarVisible = false"
      @log-out="logOut"
    />
    <router-view />
  </div>
</template>

<script>
import FuNavbar from "@/components/Navbar";
import FuSidebar from "@/components/Sidebar";
import auth from "@/services/auth";
import groups from "@/services/groups";

export default {
  inject: ["$app"],

  components: {
    FuNavbar,
    FuSidebar,
  },

  data() {
    return {
      auth,
      groups: null,
      sidebarVisible: false,
    };
  },

  created() {
    this.loadGroups();
  },

  computed: {
    ready() {
      return this.groups !== null;
    },
  },

  methods: {
    async loadGroups() {
      try {
        this.groups = await groups.list();
      } catch (error) {
        this.groups = [];
      }
    },

    async logOut() {
      await auth.logOut();
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
.default-layout {
  padding-left: 15rem;
  padding-top: 4.5rem;
}

@media screen and (max-width: 767px) {
  .default-layout {
    padding-left: 0;
  }
}
</style>
