import api from "@/services/api";

export default {
  download(groupId, name, onProgress) {
    return api.post(`/v3/${groupId}/beacons/build/${name}`, null, {
      responseType: "blob",
      onDownloadProgress: onProgress,
    });
  },

  create(groupId, config) {
    return api.post(`/v3/${groupId}/beacons/config`, this.prunedConfig(config));
  },

  delete(groupId, name) {
    return api.delete(`/v3/${groupId}/beacons/config/${name}`);
  },

  async get(groupId, name) {
    const { data } = await api.get(`/v3/${groupId}/beacons/config/${name}`);
    return data;
  },

  async list(groupId) {
    const { data } = await api.get(`/v3/${groupId}/beacons`);
    return data;
  },

  prunedConfig(config) {
    const pruned = { ...config };
    if (pruned.ipv4.dhcp) {
      delete pruned.ipv4.static;
    }
    if (pruned.ipv6.auto) {
      delete pruned.ipv6.static;
    }
    return pruned;
  },

  async version(groupId) {
    const { data } = await api.get(`/v3/${groupId}/beacons/version`);
    return data;
  },

  update(groupId, name, config) {
    return api.put(
      `/v3/${groupId}/beacons/config/${name}`,
      this.prunedConfig(config)
    );
  },

  updateMeta(groupId, name, meta) {
    return api.put(`/v3/${groupId}/beacons/meta/${name}`, meta);
  },
};
