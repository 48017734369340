<template>
  <fu-container>
    <router-view />
  </fu-container>
</template>

<script>
import FuContainer from "@/components/Container";

export default {
  components: {
    FuContainer,
  },
};
</script>
