<template>
  <fu-container width="narrow">
    <fu-base-view :title="$t('beacons.cloneBeacon', [$route.params.id])">
      <fu-beacon-form
        :initial-config="initialConfig"
        :save="save"
        @after-save="afterSave"
      />
    </fu-base-view>
  </fu-container>
</template>

<script>
import FuBaseView from "@/components/BaseView";
import FuBeaconForm from "@/components/BeaconForm";
import FuContainer from "@/components/Container";
import beacons from "@/services/beacons";

export default {
  inject: ["$app"],

  components: {
    FuBaseView,
    FuBeaconForm,
    FuContainer,
  },

  async preload({ $app, route }) {
    const config = await beacons.get($app.groupId, route.params.id);
    return {
      initialConfig: {
        ...config,
        name: null,
      },
    };
  },

  methods: {
    afterSave(config) {
      this.$router.replace(`/${this.$app.groupId}/beacons/${config.name}/edit`);
    },

    async save(config) {
      await beacons.create(this.$app.groupId, config);
    },
  },
};
</script>
