<template>
  <fu-form @submit.prevent="onSubmit">
    <fu-stack>
      <fu-stack-item v-if="beaconAccessWarning">
        <div class="warning">
          <font-awesome-icon class="icon" :icon="icons.faExclamationTriangle" />
          {{ $t("tokens.beaconAccessWarning") }}
        </div>
      </fu-stack-item>
      <fu-stack-item>
        <fu-text-input
          autofocus
          :errors="errorsFor($v.token.description, $t('tokens.description'))"
          :label="$t('tokens.description')"
          v-model="$v.token.description.$model"
        />
      </fu-stack-item>
      <fu-stack-item>
        <fu-dropdown-picker
          :label="$t('tokens.paths')"
          :options="pathOptions"
          v-model="$v.token.acl.paths.$model"
        >
          <template slot="help">
            {{ $t("tokens.pathsHelp") }}
          </template>
        </fu-dropdown-picker>
      </fu-stack-item>
      <fu-stack-item v-if="beaconFilterable">
        <fu-dropdown-picker
          :label="$t('tokens.beacons')"
          :options="beaconOptions"
          :placeholder="$t('tokens.beaconSearchPlaceholder')"
          v-model="$v.token.acl.beacons.$model"
        >
          <template slot="help">
            {{ $t("tokens.beaconsHelp") }}
          </template>
        </fu-dropdown-picker>
      </fu-stack-item>
      <fu-stack-item>
        <div class="actions">
          <slot name="actions" :vuelidate="$v" :loading="loading" />
        </div>
      </fu-stack-item>
    </fu-stack>
  </fu-form>
</template>

<script>
import {
  faCopy,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import cloneDeep from "lodash/cloneDeep";
import FuDropdownPicker from "@/components/DropdownPicker";
import FuForm from "@/components/Form";
import FuStack from "@/components/Stack";
import FuStackItem from "@/components/StackItem";
import FuTextInput from "@/components/TextInput";
import errorsMixin from "@/mixins/errors";
import tokens from "@/services/tokens";

export default {
  components: {
    FontAwesomeIcon,
    FuDropdownPicker,
    FuForm,
    FuStack,
    FuStackItem,
    FuTextInput,
  },

  mixins: [errorsMixin, validationMixin],

  props: {
    beacons: {
      type: Array,
      default() {
        return [];
      },
    },
    submit: {
      type: Function,
      required: true,
    },
    initialToken: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      loading: false,
      token: {
        acl: {
          beacons: [],
          paths: [],
        },
        description: null,
        ...cloneDeep(this.initialToken),
      },
    };
  },

  validations: {
    token: {
      acl: {
        beacons: {},
        paths: {},
      },
      description: {
        required,
      },
    },
  },

  created() {
    this.icons = { faCopy, faExclamationTriangle };
  },

  watch: {
    beaconFilterable(visible) {
      if (!visible) {
        this.token.acl.beacons = [];
      }
    },
  },

  computed: {
    beaconAccessWarning() {
      return !this.token.acl.beacons.length && this.beaconFilterable;
    },

    beaconFilterable() {
      return this.token.acl.paths.some((path) =>
        tokens.isBeaconFilterable(path)
      );
    },

    beaconOptions() {
      return this.beacons.map((beacon) => ({
        label: beacon,
        value: beacon,
      }));
    },

    pathOptions() {
      return [
        {
          label: "/backup",
          value: "/backup",
        },
        {
          label: "/beacons",
          value: "/beacons",
        },
        {
          label: "/health",
          value: "/health",
        },
        {
          label: "/instances",
          value: "/instances",
        },
        {
          label: "/observations",
          value: "/observations",
        },
        {
          label: "/observations/stats",
          value: "/observations/stats",
        },
        {
          label: "/observations/test",
          value: "/observations/test",
        },
      ];
    },
  },

  methods: {
    async onSubmit() {
      this.$v.$touch();
      this.loading = true;
      try {
        const token = await this.submit(this.token);
        this.$emit("success", { token, path: this.token.acl.path });
      } catch (error) {
        this.$emit("error", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.warning {
  background: #fffff0;
  border: 1px solid #ecc94b;
  border-radius: 3px;
  color: #975a16;
  padding: 0.5rem 1rem;
}

.warning > .icon {
  margin-right: 0.2rem;
}

.actions {
  display: flex;
  justify-content: flex-end;
}
</style>
