import { saveAs } from "file-saver";

export default {
  blob(file) {
    const blob = new FormData();
    blob.append("file", file);
    return blob;
  },

  contentType(response) {
    return response.headers["content-type"] || "application/octet-stream";
  },

  download(response, filename) {
    const contentType = this.contentType(response);
    const blob = new Blob([response.data], { contentType });
    saveAs(blob, filename || this.filename(response), true);
  },

  filename(response, fallback = "download") {
    const disposition = response.headers["content-disposition"] || "";
    const matches = disposition.match(/filename="(.+)"$/);
    return matches ? matches[1] : fallback;
  },
};
