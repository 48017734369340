<template>
  <div :class="classes">
    <div class="aside">
      <div class="icon">
        <font-awesome-icon :icon="icon" />
      </div>
    </div>
    <div class="content">
      <h2 v-if="title" class="title">{{ title }}</h2>
      <slot />
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

export default {
  components: {
    FontAwesomeIcon,
  },

  props: {
    status: {
      type: String,
      default: "info",
    },
    title: {
      type: String,
    },
  },

  created() {
    this.icons = {
      faCheckCircle,
      faExclamationCircle,
      faInfoCircle,
    };
  },

  computed: {
    classes() {
      return {
        notification: true,
        [`is-${this.status}`]: true,
      };
    },

    icon() {
      return {
        alert: this.icons.faExclamationCircle,
        info: this.icons.faInfoCircle,
        success: this.icons.faCheckCircle,
      }[this.status];
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  background: $white;
  border-radius: 3px;
  border-top: 3px solid $grey;
  box-shadow: 0 0 0 1px rgba(63, 65, 68, 0.05),
    0 1px 3px 0 rgba(63, 65, 68, 0.15);
  display: flex;
  padding: 1.5rem;
}

.title {
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0;
}

.icon {
  align-items: center;
  background: $grey-lighter;
  border-radius: 50%;
  color: $grey-dark;
  display: flex;
  font-size: 1.2rem;
  height: 2rem;
  margin-right: 1rem;
  justify-content: center;
  width: 2rem;
}

.content > .title + p {
  margin-top: 0.5rem;
}

.content > p:last-child {
  margin-bottom: 0;
}

.notification:not(:last-child) {
  margin-bottom: 1.5rem;
}

.is-alert {
  border-top-color: $red;
}

.is-alert .icon {
  background: $red-lightest;
  color: $red;
}

.is-info {
  border-top-color: $blue-dark;
}

.is-info .icon {
  background: $blue-lighter;
  color: $blue-dark;
}

.is-success {
  border-top-color: $green;
}

.is-success .icon {
  background: $green-lightest;
  color: $green-dark;
}
</style>
