<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    span: {
      type: String,
    },
  },

  computed: {
    classes() {
      return {
        "stack-item": true,
        [`is-${this.span}`]: this.span,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.is-fill {
  flex: 1;
}

.is-full {
  flex: 0 0 100%;
}

.is-half {
  flex: 0 0 50%;
}
</style>
