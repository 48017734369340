<template>
  <fu-table
    v-on="$listeners"
    v-bind="$attrs"
    :columns="columns"
    :data="pageData"
    :page="page"
    :page-size="pageSize"
    :sort-field="sortField"
    :sort-order="sortOrder"
    :total="total"
  >
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </fu-table>
</template>

<script>
import FuTable from "@/components/Table";

export default {
  components: {
    FuTable,
  },

  props: {
    columns: {
      type: Array,
    },
    data: {
      type: Array,
    },
    page: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 25,
    },
    sortField: {
      type: String,
      default: null,
    },
    sortOrder: {
      type: String,
      default: null,
    },
  },

  computed: {
    total() {
      return this.data.length;
    },

    pageData() {
      return this.sortedData.slice(
        (this.page - 1) * this.pageSize,
        this.page * this.pageSize
      );
    },

    sortedData() {
      if (!this.sortField) {
        return this.data;
      }
      const sortColumn = this.columnForField(this.sortField);
      let sortedData = [...this.data].sort((a, b) => {
        const aVal = a[this.sortField];
        const bVal = b[this.sortField];
        if (typeof sortColumn.sortCompare === "function") {
          return sortColumn.sortCompare(a, b);
        }
        switch (sortColumn.type) {
          case "date":
            return this.dateCompare(aVal, bVal);
          case "number":
            return this.numberCompare(aVal, bVal);
          default:
            return this.stringCompare(aVal, bVal);
        }
      });
      return this.sortOrder === "asc" ? sortedData : sortedData.reverse();
    },
  },

  methods: {
    columnForField(field) {
      return this.columns.find((c) => c.field === field);
    },

    dateCompare(a, b) {
      return new Date(b) - new Date(a);
    },

    numberCompare(a, b) {
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      }
      return 0;
    },

    stringCompare(a, b) {
      return (a || "").toString().localeCompare(b);
    },
  },
};
</script>
