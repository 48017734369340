import axios from "axios";

const api = axios.create({
  baseURL: "/api",
  headers: { "X-Requested-With": "XMLHttpRequest" },
});

const isPlainObject = (object) => {
  return (
    !!object && typeof object === "object" && object.constructor === Object
  );
};

const nullifyBlanks = (object) => {
  Object.keys(object).forEach((key) => {
    if (isPlainObject(object[key])) {
      nullifyBlanks(object[key]);
    } else if (object[key] === "") {
      object[key] = null;
    }
  });
};

api.interceptors.request.use(
  (config) => {
    if (config.data) {
      nullifyBlanks(config.data);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
