import isIP from "validator/es/lib/isIP";

export default (value) => {
  return !value || isIP(value);
};

export const ipv4 = (value) => {
  return !value || isIP(value, 4);
};

export const ipv6 = (value) => {
  return !value || isIP(value, 6);
};
