<template>
  <div :class="classes">
    <div class="table-wrap">
      <div ref="scrollArea" class="scroll-area" @scroll="onScrollOrResize">
        <table class="root">
          <thead class="head">
            <tr class="head-row">
              <th
                v-for="(column, index) in columns"
                :key="column.field"
                :class="headCellClasses(index)"
                @click="onClickHeadCell(index)"
              >
                {{ column.label }}
                <span v-if="sortedBy(index)" class="sort-icon">
                  <font-awesome-icon :icon="sortIcon" />
                </span>
              </th>
            </tr>
          </thead>
          <tbody class="body">
            <tr
              v-for="(row, index) in data"
              :key="index"
              class="body-row"
              @click="onClickRow($event, row)"
            >
              <td
                v-for="(column, columnIndex) in columns"
                :key="column.field"
                :class="bodyCellClasses(columnIndex)"
                :style="{ width: column.width }"
              >
                <div v-if="!rowUrl || column.noLink" class="body-cell-content">
                  <div class="body-cell-content-inner">
                    <slot :name="`column-${column.field}`" :row="row">
                      {{ row[column.field] }}
                    </slot>
                  </div>
                </div>
                <router-link v-else class="body-cell-content" :to="rowUrl(row)">
                  <div>
                    <slot :name="`column-${column.field}`" :row="row">
                      {{ row[column.field] }}
                    </slot>
                  </div>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="scroll-shadow" />
    </div>
    <div v-if="footerVisible" class="footer">
      <div class="pagination">
        <div v-if="pageSizeVisible" class="page-size">
          <div class="label">
            {{ $t("table.perPage") }}
          </div>
          <div class="select">
            <fu-select
              size="small"
              :options="pageSizeOptions"
              :value="pageSize"
              @input="onChangePageSize"
            />
          </div>
        </div>
        <div v-if="paginationVisible" class="prev-next">
          <div class="label">{{ $t("table.page", [page, pageCount]) }}</div>
          <div class="buttons">
            <fu-button-group>
              <fu-button
                size="small"
                :icon="icons.faArrowLeft"
                :disabled="!prevEnabled"
                @click="goToPrevPage"
              />
              <fu-button
                size="small"
                :icon="icons.faArrowRight"
                :disabled="!nextEnabled"
                @click="goToNextPage"
              />
            </fu-button-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import throttle from "lodash/throttle";
import FuButton from "@/components/Button";
import FuButtonGroup from "@/components/ButtonGroup";
import FuSelect from "@/components/Select";

export default {
  components: {
    FontAwesomeIcon,
    FuButton,
    FuButtonGroup,
    FuSelect,
  },

  props: {
    align: {
      type: String,
    },
    columns: {
      type: Array,
    },
    data: {
      type: Array,
    },
    page: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 25,
    },
    pageSizes: {
      type: Array,
      default() {
        return [25, 50, 100];
      },
    },
    rowUrl: {
      type: Function,
      default: null,
    },
    sortField: {
      type: String,
      default: null,
    },
    sortOrder: {
      type: String,
      default: null,
    },
    total: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      scrolledToEnd: true,
    };
  },

  created() {
    this.icons = {
      faArrowDown,
      faArrowLeft,
      faArrowRight,
      faArrowUp,
    };

    window.addEventListener("resize", this.onScrollOrResize);
  },

  mounted() {
    this.updateScroll();
  },

  destroyed() {
    window.removeEventListener("resize", this.onScrollOrResize);
  },

  watch: {
    data() {
      this.onScrollOrResize();
    },
  },

  computed: {
    classes() {
      return {
        table: true,
        [`is-align-${this.align}`]: !!this.align,
        "is-clickable": !!this.rowUrl || !!this.$listeners["click-row"],
        "is-scrolled-to-end": this.scrolledToEnd,
      };
    },

    footerVisible() {
      return this.pageSizeVisible || this.paginationVisible;
    },

    nextEnabled() {
      return this.page < this.pageCount;
    },

    prevEnabled() {
      return this.page > 1;
    },

    pageCount() {
      return Math.ceil(this.total / this.pageSize);
    },

    pageSizeOptions() {
      return this.pageSizes.map((pageSize) => ({
        label: pageSize,
        value: pageSize,
      }));
    },

    pageSizeVisible() {
      return !!this.pageSizes && !!this.pageSizes.length;
    },

    paginationVisible() {
      return this.pageCount > 1;
    },

    sortIcon() {
      return this.sortOrder === "asc"
        ? this.icons.faArrowUp
        : this.icons.faArrowDown;
    },
  },

  methods: {
    headCellClasses(index) {
      return {
        "head-cell": true,
        "is-sortable": this.sortable(index),
        ...(this.columnForIndex(index).classes || {}),
      };
    },

    bodyCellClasses(index) {
      return {
        "body-cell": true,
        ...(this.columnForIndex(index).classes || {}),
      };
    },

    columnForField(field) {
      return this.columns.find((c) => c.field === field);
    },

    columnForIndex(index) {
      return this.columns[index];
    },

    onChangePageSize(pageSize) {
      this.$emit("update:page-size", Number(pageSize));
      this.$emit("update:page", 1);
    },

    onClickHeadCell(index) {
      const column = this.columnForIndex(index);
      if (!column.sortable) {
        return;
      }
      if (this.sortField === column.field && this.sortOrder === "desc") {
        this.$emit("update:sort-field", null);
        this.$emit("update:sort-order", null);
      } else if (this.sortField === column.field) {
        this.$emit(
          "update:sort-order",
          this.sortOrder === "desc" ? "asc" : "desc"
        );
      } else {
        this.$emit("update:sort-field", column.field);
        this.$emit("update:sort-order", "asc");
      }
    },

    onClickRow(evt, row) {
      var selection = window.getSelection();
      if (
        selection.toString().length &&
        selection.containsNode(evt.target, true)
      ) {
        return;
      }
      this.$emit("click-row", row);
    },

    onScrollOrResize: throttle(function () {
      this.$nextTick(() => {
        this.updateScroll();
      });
    }, 100),

    goToNextPage() {
      if (this.page < this.pageCount) {
        this.$emit("update:page", this.page + 1);
      }
    },

    goToPrevPage() {
      if (this.page > 1) {
        this.$emit("update:page", this.page - 1);
      }
    },

    sortable(index) {
      return !!this.columnForIndex(index).sortable;
    },

    sortedBy(index) {
      return (
        this.sortField && this.sortField === this.columnForIndex(index).field
      );
    },

    updateScroll() {
      const { clientWidth, scrollLeft, scrollWidth } = this.$refs.scrollArea;
      this.scrolledToEnd = scrollLeft + clientWidth >= scrollWidth - 30;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-wrap {
  position: relative;
}

.root {
  border-spacing: 0;
  height: 100%;
  text-align: left;
  white-space: nowrap;
  width: 100%;
}

.scroll-area {
  overflow-x: auto;
}

.head-cell {
  background: $white;
  border-bottom: 1px solid $grey-lighter;
  color: $grey-darker;
  font-weight: bold;
  padding: 1rem 1.5rem;
}

.head-cell.is-sortable {
  cursor: pointer;
}

.body-cell {
  background: $white;
  height: 100%;
  padding: 0;
}

.body-cell-content {
  align-items: center;
  color: initial;
  display: flex;
  height: 100%;
  text-decoration: initial;
}

.body-cell-content > div,
.body-cell-content-inner {
  padding: 1rem 1.5rem;
}

.body-row {
  height: 100%;
}

.body-row:not(:last-child) .body-cell {
  border-bottom: 1px solid $grey-lighter;
}

.sort-icon {
  font-size: 0.8rem;
  margin-left: 0.25rem;
}

.footer {
  border-top: 1px solid $grey-lighter;
  color: $grey-dark;
  padding: 1rem 1.5rem;
}

.pagination {
  align-items: center;
  display: flex;
}

.pagination .page-size {
  align-items: center;
  display: flex;
}

.pagination .page-size > .select {
  margin-left: 0.75rem;
}

.pagination .prev-next {
  align-items: center;
  display: flex;
  margin-left: auto;
}

.pagination .prev-next > .buttons {
  margin-left: 0.75rem;
}

.scroll-shadow {
  background: linear-gradient(90deg, rgba($grey, 0), rgba($grey, 0.25));
  bottom: 0;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.25s;
  width: 30px;
}

.is-align-top .body-cell-content {
  align-items: flex-start;
}

.is-clickable .body-row {
  cursor: pointer;
}

.is-clickable .body-row:hover .body-cell {
  background: $grey-lightest;
}

.is-scrolled-to-end .scroll-shadow {
  opacity: 0;
}
</style>
