<template>
  <component
    :is="tagName"
    :class="classes"
    :disabled="disabled || loading"
    v-bind="props"
    v-on="$listeners"
  >
    <div class="inner">
      <div v-if="icon" class="icon">
        <font-awesome-icon :icon="icon" />
      </div>
      <div v-if="$slots.default" class="text">
        <slot />
      </div>
    </div>
    <div v-if="loading" class="loader">
      <div />
      <div />
      <div />
    </div>
  </component>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  components: {
    FontAwesomeIcon,
  },

  props: {
    destructive: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    fullWidth: {
      type: Boolean,
    },
    href: {
      type: String,
    },
    icon: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
    primary: {
      type: Boolean,
    },
    size: {
      type: String,
    },
    to: {
      type: [Object, String],
    },
    type: {
      type: String,
      default: "button",
    },
  },

  computed: {
    classes() {
      return {
        button: true,
        "is-destructive": this.destructive,
        "is-disabled": this.disabled,
        "is-full-width": this.fullWidth,
        "is-loading": this.loading,
        "is-primary": this.primary,
        [`is-size-${this.size}`]: this.size,
      };
    },

    isRegularLink() {
      return !!this.href;
    },

    isRouterLink() {
      return !!this.to;
    },

    props() {
      if (this.isRegularLink) {
        return {
          href: this.href,
        };
      }
      if (this.isRouterLink) {
        return {
          to: this.to,
        };
      }
      return {
        type: this.type,
      };
    },

    tagName() {
      if (this.isRegularLink) {
        return "a";
      }
      if (this.isRouterLink) {
        return "router-link";
      }
      return "button";
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.button {
  background: $grey-lightest;
  border: 1px solid $grey-light;
  border-radius: 3px;
  color: $grey-darker;
  cursor: pointer;
  display: inline-flex;
  font: inherit;
  justify-content: center;
  margin: 0;
  padding: 0.5rem 1rem;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
}

.button:focus {
  border-color: $blue;
  box-shadow: 0 0 0 2px $blue-lighter;
  outline: 0;
}

.button:active {
  background: $grey-light;
}

.inner {
  align-items: center;
  display: flex;
  transition: opacity 0.15s;
}

.text:not(:first-child) {
  margin-left: 0.75em;
}

.loader {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.15s;
}

.loader div {
  animation: loader 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border: 0.15em solid currentColor;
  border-color: currentColor transparent transparent transparent;
  border-radius: 50%;
  display: block;
  height: 1.2em;
  position: absolute;
  width: 1.2em;
}

.loader div:nth-child(1) {
  animation-delay: -0.3s;
}

.loader div:nth-child(2) {
  animation-delay: -0.2s;
}

.loader div:nth-child(3) {
  animation-delay: -0.1s;
}

.is-full-width {
  width: 100%;
}

.is-full-width > .inner {
  justify-content: center;
}

.is-loading > .inner {
  opacity: 0;
}

.is-destructive {
  background: $red;
  border-color: darken($red, 5%);
  color: $white;
}

.is-destructive:active {
  background: darken($red, 5%);
  border-color: darken($red, 10%);
  color: $white;
}

.is-disabled {
  background: $grey-lightest !important;
  border-color: $grey-light !important;
  color: $grey !important;
  pointer-events: none;
}

.is-primary {
  background: $blue-dark;
  border-color: darken($blue-dark, 5%);
  color: $white;
}

.is-primary:active {
  background: darken($blue-dark, 5%);
  border-color: darken($blue-dark, 5%);
  box-shadow: none;
}

.is-size-small {
  padding: 0.4rem 0.8rem;
  font-size: 0.9rem;
}
</style>
