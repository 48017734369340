<template>
  <div class="card-section">
    <div class="header" v-if="title">
      <h3 class="title">{{ title }}</h3>
    </div>
    <div class="body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding: 1.5rem 1.5rem 0;
}

.title {
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;
}

.body {
  padding: 1.5rem;
}

.card-section + .card-section {
  border-top: 1px solid $grey-light;
}
</style>
