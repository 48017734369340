import Cookies from "js-cookie";
import api from "@/services/api";

export default {
  updatePassword(password) {
    return api.put("/v3/password", {
      password,
    });
  },

  async deleteAccount(password) {
    try {
      await api.delete("/v3/user", {
        data: { password },
      });
      Cookies.remove("user");
    } catch (error) {
      throw error;
    }
  },
};
