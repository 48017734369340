import Vue from "vue";
import VueI18n from "vue-i18n";
import VTooltip from "v-tooltip";
import Router from "vue-router";
import preload from "@badrap/preload";
import ProgressBar from "@badrap/bar-of-progress";
import routdir from "@badrap/routdir";
import ToastCenter from "@/components/ToastCenter";
import api from "@/services/api";
import auth from "@/services/auth";
import App from "@/App";
import lang from "@/lang";

Vue.config.productionTip = false;

Vue.use(Router);
Vue.use(VueI18n);
Vue.use(VTooltip);

export const i18n = new VueI18n({
  locale: "en",
  messages: lang,
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.request.responseURL.indexOf("/v3/session") !== -1) {
      return;
    }
    if (error.response.status === 401) {
      if (router.currentRoute.path === "/join") {
        return Promise.reject(error);
      }
      await auth.logOut();
      if (router.currentRoute.path !== "/login") {
        router.go("/login");
      }
    }
    return Promise.reject(error);
  }
);

const toastCenter = new Vue(ToastCenter);
toastCenter.$mount();
document.body.appendChild(toastCenter.$el);

const progressBar = new ProgressBar({
  color: "#4dc0b5",
  size: 2,
});

const appState = Vue.observable({
  groupId: null,
});

const providers = {
  $app: appState,
  $auth: { provider: null },
  $i18n: i18n,
  $progress: progressBar,
  $toast: toastCenter,
};

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: preload(routdir(require.context("./routes", true, /\.vue$/)), {
    context: providers,
    beforePreload() {
      progressBar.start();
    },
    afterPreload() {
      progressBar.finish();
    },
  }),
});

const guestPaths = ["/login", "/reset-password"];

router.beforeEach((to, _from, next) => {
  if (to.params.group) {
    appState.groupId = to.params.group;
  }
  const isGuestPath = guestPaths.includes(to.path);
  if (!auth.user && !isGuestPath && to.path !== "/join") {
    return next("/login");
  } else if (auth.user && isGuestPath) {
    return next("/");
  }
  return next();
});

const checkSession = () =>
  api
    .get("/v3/session")
    .then(() => true)
    .catch(() => false);

api
  .get("/v3/settings")
  .then((res) => {
    i18n.locale = res.data.locale;
    providers.$auth.provider = res.data.auth;
    return checkSession();
  })
  .then((sessionExists) => {
    if (!sessionExists) {
      router.go("/login");
    }
    const vm = new Vue({
      i18n,
      router,
      render: (h) => h(App),
      provide: () => providers,
    });
    router.onReady(() => {
      vm.$mount("#app");
    });
  });
