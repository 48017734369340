<template>
  <fu-container width="narrow">
    <fu-base-view :title="$t('overview.title')">
      <div class="stats-cards">
        <fu-card>
          <fu-card-section>
            <fu-stats
              :label="$t('overview.configsTitle')"
              :help="$t('overview.configsHelp')"
            >
              {{ configs.length }}
              <span class="stats-sub">{{
                $tc("overview.config", configs.length)
              }}</span>
              <template #icon>
                <font-awesome-icon :icon="icons.faWifi" />
              </template>
            </fu-stats>
          </fu-card-section>
        </fu-card>
        <fu-card>
          <fu-card-section>
            <fu-stats
              :label="$t('overview.leaksTitle')"
              :help="$t('overview.leaksHelp')"
            >
              {{ leakedConfigsCount }}
              <span class="stats-sub">{{
                $tc("overview.config", leakedConfigsCount)
              }}</span>
              <template v-if="leakedConfigsCount !== leakedInstancesCount">
                {{ leakedInstancesCount }}
                <span class="stats-sub">{{
                  $tc("overview.instance", leakedInstancesCount)
                }}</span>
              </template>
              <template #icon>
                <font-awesome-icon :icon="icons.faEye" />
              </template>
            </fu-stats>
          </fu-card-section>
        </fu-card>
      </div>
      <fu-card class="chart-card">
        <fu-card-section :title="$t('overview.summaryTitle')">
          <fu-chart :items="summary" />
        </fu-card-section>
      </fu-card>
      <fu-card class="observed-beacons-card" :title="$t('overview.tableTitle')">
        <template v-if="observedBeacons.length">
          <fu-observation-legend slot="header" />
          <fu-table
            class="instances"
            :columns="columns"
            :data="observedBeacons"
            :page.sync="page"
            :page-size.sync="pageSize"
            :page-sizes="[]"
            :row-url="observationsUrl"
          >
            <template #column-name="{ row }">
              <div>{{ row.name }}</div>
              <fu-instance-indicator v-if="row.name !== row.config">
                {{ row.config }}
              </fu-instance-indicator>
            </template>
            <template #column-protocols="{ row }">
              <div class="protocols">
                <fu-observation-badge
                  v-for="observation in row.observations"
                  :key="observation.protocol"
                  :protocol="observation.protocol"
                  :timestamp="observation.timestamp"
                />
              </div>
            </template>
          </fu-table>
        </template>
        <template v-else>
          <fu-empty-state
            :title="$t('overview.emptyTitle')"
            :icon="icons.faCheckSquare"
          >
            <div>{{ $t("overview.emptyDescription") }}</div>
            <div v-if="$i18n.locale === 'en'">
              <a
                href="/manual/alerts/overview/#retention-policy"
                target="_blank"
                rel="noreferrer noopener"
                >{{ $t("overview.retentionPolicy") }}</a
              >
            </div>
          </fu-empty-state>
        </template>
      </fu-card>
    </fu-base-view>
  </fu-container>
</template>

<script>
import { differenceInDays } from "date-fns";
import {
  faCheckSquare,
  faEye,
  faWifi,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import FuBaseView from "@/components/BaseView";
import FuCard from "@/components/Card";
import FuCardSection from "@/components/CardSection";
import FuChart from "./_components/Chart";
import FuContainer from "@/components/Container";
import FuEmptyState from "@/components/EmptyState";
import FuInstanceIndicator from "@/components/InstanceIndicator";
import FuObservationBadge from "./_components/ObservationBadge";
import FuObservationLegend from "./_components/ObservationLegend";
import FuStats from "./_components/Stats";
import FuTable from "@/components/LocalTable";
import beacons from "@/services/beacons";
import instances from "@/services/instances";
import observations from "@/services/observations";

export default {
  inject: ["$app"],

  components: {
    FontAwesomeIcon,
    FuBaseView,
    FuCard,
    FuCardSection,
    FuChart,
    FuContainer,
    FuEmptyState,
    FuInstanceIndicator,
    FuObservationBadge,
    FuObservationLegend,
    FuStats,
    FuTable,
  },

  async preload({ $app }) {
    return {
      configs: Object.keys(await beacons.list($app.groupId)),
      instances: await instances.list($app.groupId),
      summary: await observations.summary($app.groupId),
    };
  },

  data() {
    return {
      page: 1,
      pageSize: 100,
      columns: [
        {
          label: this.$t("overview.tableBeacon"),
          field: "name",
        },
        {
          label: this.$t("overview.tableProtocols"),
          field: "protocols",
          width: "99%",
        },
      ],
    };
  },

  created() {
    this.icons = { faCheckSquare, faEye, faWifi };
  },

  computed: {
    leakCountByConfig() {
      return this.instances.reduce((acc, instance) => {
        if (!this.configs.includes(instance.config)) {
          return acc;
        }

        acc[instance.config] = acc[instance.config] || 0;
        acc[instance.config]++;

        return acc;
      }, {});
    },

    leakedConfigsCount() {
      return Object.keys(this.leakCountByConfig).length;
    },

    leakedInstancesCount() {
      return new Set(this.instances.map((i) => i.name)).size;
    },

    observedBeacons() {
      return this.instances
        .reduce(
          (acc, instance) => {
            const key = `${instance.config}${instance.name}`;
            if (!acc.seen[key]) {
              acc.seen[key] = true;
              acc.data.push(instance);
            }
            return acc;
          },
          { seen: {}, data: [] }
        )
        .data.map((instance) => {
          return {
            config: instance.config,
            name: instance.name,
            observations: this.instanceObservations(instance.config, instance.name),
          };
        })
        .filter(({ observations }) => {
          return Boolean(observations.length);
        });
    },
  },

  methods: {
    instanceObservations(config, name, maxDaysAgo = 30) {
      return this.instances
        .filter((instance) => {
          return instance.config === config && instance.name === name;
        })
        .map((instance) => {
          return { ...instance, lastSeen: new Date(instance.lastSeen) };
        })
        .filter(({ lastSeen }) => {
          return differenceInDays(new Date(), lastSeen) < maxDaysAgo;
        })
        .map(({ protocol, lastSeen }) => {
          return { protocol, timestamp: lastSeen };
        })
        .reduce(
          (acc, observation) => {
            if (!acc.seen[observation.protocol]) {
              acc.seen[observation.protocol] = true;
              acc.data.push(observation);
            }
            return acc;
          },
          { seen: {}, data: [] }
        )
        .data.sort((a, b) => {
          return a.lastSeen - b.lastSeen;
        });
    },

    observationsUrl(instance) {
      return `/${this.$app.groupId}/observations?config=${instance.config}&instance=${instance.name}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.stats-cards {
  display: flex;
}

.stats-cards > * {
  flex: 1;
}

.stats-cards > * + * {
  margin-left: 1.5rem;
}

.stats-sub {
  color: $grey-dark;
  font-size: 1rem;
}

.chart-card {
  margin: 1.5rem 0;
}

.observed-beacons-card ::v-deep .empty-state {
  border-top: 1px solid $grey-lighter;
  margin: 1.25rem 0 0;
  padding: 3rem;
  max-width: none;
}

.instances {
  border-top: 1px solid $grey-lighter;
  margin-top: 1.5rem;
}

.instances ::v-deep td {
  vertical-align: top;
}

.beacon {
  margin: 0 0 1rem;
}

.protocols {
  display: flex;
}

.protocols > * + * {
  margin-left: 0.3rem;
}

@media screen and (max-width: 1023px) {
  .stats-cards {
    display: block;
  }

  .stats-cards > * + * {
    margin: 1rem 0 0;
  }
}
</style>
