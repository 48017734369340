import api from "@/services/api";

export default {
  async get(groupId) {
    const { data } = await api.get(`/v3/${groupId}/home`);
    return data;
  },

  async receivers(groupId) {
    const { data } = await api.get(`/v3/${groupId}/home/receivers`);
    return data;
  },

  update(groupId, config) {
    return api.put(`/v3/${groupId}/home`, config);
  },
};
