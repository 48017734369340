import api from "@/services/api";

export default {
  async list() {
    const { data } = await api.get("/v3/groups");
    const items = [];
    for (const [id, name] of Object.entries(data)) {
      items.push({ id, name });
    }
    return items.sort((a, b) =>
      a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase())
    );
  },
};
