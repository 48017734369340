import { i18n } from "@/main";

const platforms = [
  {
    name: "ova",
    displayName: i18n.t("platforms.ova"),
    hideInterface: false,
  },
  {
    name: "raspberrypi",
    displayName: i18n.t("platforms.raspberrypi"),
    hideInterface: false,
  },
  {
    name: "linux-amd64",
    displayName: i18n.t("platforms.linuxAmd64"),
    hideInterface: true,
  },
  {
    name: "linux-armv7",
    displayName: i18n.t("platforms.linuxARMv7"),
    hideInterface: true,
  },
  {
    name: "linux-aarch64",
    displayName: i18n.t("platforms.linuxAArch64"),
    hideInterface: true,
  },
  {
    name: "windows-amd64",
    displayName: i18n.t("platforms.windowsAmd64"),
    hideInterface: true,
  },
  {
    name: "windows-x86",
    displayName: i18n.t("platforms.windowsX86"),
    hideInterface: true,
  },
];

export default {
  get all() {
    return platforms;
  },

  forName(name) {
    return platforms.find((p) => p.name === name);
  },

  get options() {
    return platforms.map((p) => ({
      label: p.displayName,
      value: p.name,
    }));
  },
};
