<template>
  <div class="progress-overlay">
    <div class="overlay">
      <div class="box">
        <div class="message">{{ message }}</div>
        <div class="progress">
          <div class="bar" :style="{ width: `${progress}%` }" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
    },
    progress: {
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  align-items: center;
  background: rgba(0, 0, 0, 0.25);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.box {
  background: $white;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(63, 65, 68, 0.05),
    0 1px 3px 0 rgba(63, 65, 68, 0.15);
  overflow: hidden;
  width: 20rem;
}

.message {
  font-size: 1.2rem;
  font-weight: 500;
  padding: 1.5rem;
  text-align: center;
}

.progress {
  background: $blue-lighter;
  height: 0.25rem;
}

.progress > .bar {
  background: $blue;
  height: 100%;
  transition: width 0.5s ease;
}
</style>
