<template>
  <fu-dialog
    :title="$t('users.removeDialogTitle', [username])"
    width="narrow"
    @close="$emit('close')"
  >
    <i18n path="users.removeDialogConfirmation" tag="p">
      <strong>{{ username }}</strong>
    </i18n>
    <template slot="actions">
      <fu-button-group>
        <fu-button @click="$emit('close')">
          {{ $t("users.removeDialogCancel") }}
        </fu-button>
        <fu-button primary :loading="loading" @click="confirm">
          {{ $t("users.removeDialogRemove") }}
        </fu-button>
      </fu-button-group>
    </template>
  </fu-dialog>
</template>

<script>
import FuButton from "@/components/Button";
import FuButtonGroup from "@/components/ButtonGroup";
import FuDialog from "@/components/Dialog";
import { AppError } from "@/helpers/errors";
import auth from "@/services/auth";
import users from "@/services/users";

export default {
  inject: ["$app", "$toast"],

  components: {
    FuButton,
    FuButtonGroup,
    FuDialog,
  },

  props: {
    username: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    async confirm() {
      this.loading = true;
      try {
        await users.delete(this.$app.groupId, this.username);
        this.$toast.raise(this.$t("users.removeSuccess", [this.username]));
        if (auth.user.username === this.username) {
          this.$router.replace("/");
          return;
        }
        this.$emit("success");
      } catch (error) {
        this.$toast.raiseError(
          AppError.create(this.$t("users.removeError", [this.username]), error)
        );
        this.$emit("error", error);
      } finally {
        this.loading = false;
        this.$emit("close");
      }
    },
  },
};
</script>
