<template>
  <div class="navbar">
    <div class="items hide-large">
      <a class="item" href="#" @click.stop="$emit('toggle-menu')">
        <span class="icon">
          <font-awesome-icon :icon="icons.faBars" />
        </span>
        <span class="text">
          {{ $t("navigation.menu") }}
        </span>
      </a>
    </div>
    <router-link class="brand" to="/">
      <img :src="require(`@/assets/images/${$t('_paths.logo')}`)" alt="" />
    </router-link>
    <div class="items">
      <a
        v-if="$t('_paths.manual')"
        class="item hide-small"
        :href="$t('_paths.manual')"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span class="icon">
          <font-awesome-icon :icon="icons.faBook" />
        </span>
        <span class="text">
          {{ $t("navigation.manual") }}
        </span>
      </a>
      <fu-dropdown>
        <template slot="trigger">
          <button class="item" type="button">
            <span class="icon">
              <font-awesome-icon :icon="icons.faUser" />
            </span>
            <span class="text">
              {{ username }}
            </span>
          </button>
        </template>
        <fu-menu>
          <fu-menu-item v-if="$auth.provider === 'internal'" to="/settings">
            {{ $t("navigation.settings") }}
          </fu-menu-item>
          <fu-menu-item @click="$emit('log-out')">
            {{ $t("navigation.logOut") }}
          </fu-menu-item>
        </fu-menu>
      </fu-dropdown>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faBars,
  faBook,
  faSpinner,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import FuDropdown from "@/components/Dropdown";
import FuMenu from "@/components/Menu";
import FuMenuItem from "@/components/MenuItem";

export default {
  inject: ["$auth"],

  components: {
    FontAwesomeIcon,
    FuDropdown,
    FuMenu,
    FuMenuItem,
  },

  props: {
    username: {
      type: String,
    },
  },

  created() {
    this.icons = {
      faBars,
      faBook,
      faSpinner,
      faUser,
    };
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  align-items: center;
  background: $blue-darkest;
  display: flex;
  height: 4.5rem;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
}

.brand {
  align-items: center;
  display: flex;
  height: 100%;
  padding: 0 1.5rem;
}

.brand > img {
  display: block;
  max-height: 48px;
  max-width: 200px;
}

.items {
  align-items: center;
  display: flex;
  padding: 0 0.5rem;
}

.items:last-child {
  margin-left: auto;
}

.item {
  align-items: center;
  background: none;
  border: 0;
  color: $white;
  cursor: pointer;
  display: flex;
  font: inherit;
  height: 4.5rem;
  padding: 0 1rem;
  text-decoration: none;
}

.item > .icon + .text {
  margin-left: 1rem;
}

::v-deep .popover {
  margin-right: 0.25rem;
}

@media screen and (max-width: 1023px) {
  .brand {
    flex: 1;
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .item > .icon {
    font-size: 1.25rem;
  }

  .item > .text {
    display: none;
  }
}
</style>
