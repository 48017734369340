<template>
  <div class="empty-state">
    <div class="icon">
      <font-awesome-icon :icon="icon" />
    </div>
    <h1 class="title">
      {{ title }}
    </h1>
    <h2 class="content">
      <slot />
    </h2>
    <div class="action" v-if="actionVisible">
      <fu-button primary :to="actionUrl">
        {{ actionText }}
      </fu-button>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import FuButton from "@/components/Button";

export default {
  components: {
    FontAwesomeIcon,
    FuButton,
  },

  props: {
    actionText: {
      type: String,
    },
    actionUrl: {
      type: String,
    },
    icon: {
      type: Object,
    },
    title: {
      type: String,
    },
  },

  computed: {
    actionVisible() {
      return !!this.actionUrl && !!this.actionText;
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-state {
  align-items: center;
  flex-direction: column;
  display: flex;
  min-height: 100%;
  justify-content: center;
  margin: 2rem auto;
  max-width: 480px;
  padding: 2rem;
}

.icon {
  align-items: center;
  background: $blue-dark;
  border-radius: 50%;
  color: $white;
  display: flex;
  font-size: 2.5rem;
  height: 5rem;
  justify-content: center;
  margin-bottom: 1rem;
  width: 5rem;
}

.title {
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0;
  text-align: center;
}

.content {
  color: $grey-darker;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.8rem;
  margin: 0.25rem 0 0;
  text-align: center;
}

.action {
  margin-top: 1rem;
}

@media screen and (max-width: 767px) {
  .icon {
    font-size: 3rem;
    height: 5.5rem;
    width: 5.5rem;
  }

  .title {
    font-size: 1.4rem;
  }

  .subtitle {
    font-size: 1.2rem;
  }
}
</style>
