<template>
  <fu-container width="narrow">
    <fu-base-view :title="$t('beacons.addBeacon')">
      <fu-beacon-form
        :initial-config="initialConfig"
        :save="save"
        @after-save="afterSave"
      />
    </fu-base-view>
  </fu-container>
</template>

<script>
import FuBaseView from "@/components/BaseView";
import FuBeaconForm from "@/components/BeaconForm";
import FuContainer from "@/components/Container";
import beacons from "@/services/beacons";
import home from "@/services/home";

export default {
  inject: ["$app"],

  components: {
    FuBaseView,
    FuBeaconForm,
    FuContainer,
  },

  async preload({ $app }) {
    const { dnsDomain, addresses: receivers } = await home.receivers(
      $app.groupId
    );
    return {
      initialConfig: {
        dnsDomain,
        receivers,
      },
    };
  },

  methods: {
    afterSave() {
      this.$router.replace(`/${this.$app.groupId}/beacons`);
    },

    save(config) {
      return beacons.create(this.$app.groupId, config);
    },
  },
};
</script>
