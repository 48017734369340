<template>
  <transition name="dialog" appear>
    <div :class="classes">
      <div class="box">
        <div class="header" v-if="title">
          <h2 class="title">{{ title }}</h2>
          <button class="close" type="button" @click="close">&times;</button>
        </div>
        <div class="body" v-if="$slots.default">
          <slot />
        </div>
        <div class="footer" v-if="$slots.actions">
          <div class="actions">
            <slot name="actions" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    visible: {
      type: Boolean,
    },
    width: {
      type: String,
    },
  },

  mounted() {
    document.addEventListener("keydown", this.handleKeyDown);
    this.autofocus();
  },

  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKeyDown);
  },

  computed: {
    classes() {
      return {
        dialog: true,
        [`is-width-${this.width}`]: this.width,
      };
    },
  },

  methods: {
    autofocus() {
      const autofocusable = this.$el.querySelector("[autofocus]");
      if (!autofocusable) {
        return;
      }
      autofocusable.focus();
    },

    close() {
      this.$emit("close", false);
    },

    handleKeyDown(evt) {
      if (evt.key === "Escape") {
        this.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-enter {
  opacity: 0;
}

.dialog-leave-active {
  opacity: 0;
}

.dialog-enter .box,
.dialog-leave-active .box {
  transform: scale(1.1);
}

.dialog {
  background: rgba($black, 0.5);
  bottom: 0;
  left: 0;
  padding: 20vh 2rem 2rem;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.25s;
  z-index: 100;
}

.box {
  background: $white;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(63, 65, 68, 0.05),
    0 1px 3px 0 rgba(63, 65, 68, 0.15);
  margin: 0 auto;
  max-height: 100%;
  max-width: 720px;
  overflow: auto;
  transition: transform 0.15s;
  width: 100%;
}

.header {
  align-items: center;
  display: flex;
  padding: 1rem 1.5rem 0;
  position: relative;
}

.header > .title {
  font-size: 1.3rem;
  font-weight: 500;
  margin: 0;
}

.header > .close {
  align-items: center;
  background: none;
  border: 0;
  bottom: 0;
  cursor: pointer;
  display: flex;
  font-size: 2rem;
  height: 4rem;
  justify-content: center;
  outline: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 4rem;
}

.body {
  overflow: auto;
  padding: 1.5rem;
  white-space: break-word;
}

.body > :first-child {
  margin-top: 0;
}

.body > :last-child {
  margin-bottom: 0;
}

.footer {
  padding: 0 1.5rem 1rem;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.is-width-narrow .box {
  max-width: 480px;
}
</style>
