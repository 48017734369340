<template>
  <fu-container :width="tokens.length ? 'default' : 'narrow'">
    <fu-base-view :actions="actions" :title="$t('configure.tokens')">
      <fu-notification
        v-if="!tokens.length"
        :title="$t('tokens.addTokenTitle')"
      >
        {{ $t("tokens.addTokenHelp") }}
        <a href="/manual/alerts/api/" target="_blank" rel="noreferrer noopener">
          {{ $t("tokens.apiHelp") }}
        </a>
      </fu-notification>
      <fu-card v-else>
        <fu-token-list
          :tokens="tokens"
          @delete="(token) => (deletedToken = token)"
          @edit="(token) => (editedToken = token)"
        />
      </fu-card>
      <fu-create-token-dialog
        v-if="createToken"
        :beacons="beacons"
        @after-save="refresh"
        @close="createToken = false"
      />
      <fu-edit-token-dialog
        v-if="editedToken"
        :beacons="beacons"
        :token="editedToken"
        @after-save="afterUpdate"
        @close="editedToken = null"
      />
      <fu-delete-dialog
        v-if="deletedToken"
        :target="deletedToken.description"
        :delete="deleteToken"
        @close="deletedToken = null"
        @success="refresh"
      />
    </fu-base-view>
  </fu-container>
</template>

<script>
import FuBaseView from "@/components/BaseView";
import FuCard from "@/components/Card";
import FuContainer from "@/components/Container";
import FuCreateTokenDialog from "./_components/CreateTokenDialog";
import FuEditTokenDialog from "./_components/EditTokenDialog";
import FuDeleteDialog from "@/components/DeleteDialog";
import FuNotification from "@/components/Notification";
import FuTokenList from "./_components/TokenList";
import beacons from "@/services/beacons";
import tokens from "@/services/tokens";

export default {
  inject: ["$app", "$progress"],

  components: {
    FuBaseView,
    FuCard,
    FuContainer,
    FuCreateTokenDialog,
    FuDeleteDialog,
    FuEditTokenDialog,
    FuNotification,
    FuTokenList,
  },

  async preload({ $app }) {
    return {
      beacons: Object.keys(await beacons.list($app.groupId)),
      tokens: await tokens.list($app.groupId),
    };
  },

  data() {
    return {
      beacons: [],
      createToken: false,
      deletedToken: null,
      editedToken: null,
    };
  },

  computed: {
    actions() {
      return [
        {
          handler: () => (this.createToken = true),
          primary: true,
          text: this.$t("tokens.create"),
        },
      ];
    },
  },

  methods: {
    afterUpdate() {
      this.refresh();
      this.editedToken = false;
    },

    deleteToken() {
      const { id } = this.deletedToken;
      return tokens.delete(this.$app.groupId, id);
    },

    async refresh() {
      this.$progress.start();
      this.tokens = await tokens.list(
        this.$app.groupId,
        this.$route.params.group
      );
      this.$progress.finish();
    },
  },
};
</script>
